import { IPage, ISearch, ISort } from "../../../store/reducers/tableReducer/type";
import { IDataSource, IInitialValues } from "./type";

export const prepareFetchParameters = (search?: ISearch, sort?: ISort, page?: IPage): string => {
    const params = new URLSearchParams();

    page?.size && params.append("limit", page?.size.toString());
    typeof page?.number === "number" && params.append("offset", (page.size * page.number).toString());
    search?.key && search?.value && params.append(search.key, search.value);
    sort?.key && sort.order && params.append("ordering", sort.order === "ascend" ? sort.key : `-${sort.key}`)

    return params.size ? `?${params.toString()}` : '';
};

export const cleanupAfterSubmit = (
    setIsModalOpen: (open: boolean) => void,
    setSelectedUser: (policy?: IDataSource) => void,
    formikReset: () => void,
    handleFetchData: () => void
) => {
    setIsModalOpen(false);
    setSelectedUser(undefined);
    formikReset();
    handleFetchData();
};

export const setFormForSelectedUser = (selectedUser: IDataSource): IInitialValues => {
    return {
        id: selectedUser.id,
        email: selectedUser.email,
        first_name: selectedUser.first_name,
        last_name: selectedUser.last_name,
        position: selectedUser.position,
        department: selectedUser.department,
        phone_number: selectedUser.phone_number,
        address: selectedUser.address,
        role: selectedUser.role,
        organization: selectedUser.organization,
        created_at: selectedUser.created_at,
        created_by: selectedUser.created_by,
        is_verified: selectedUser.is_verified,
        is_active: selectedUser.is_active
    };
};
