import { FC, useRef } from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Flex,
    Input,
    Select,
    Typography
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ModalDialog } from "../";
import { IProps } from "./type";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import { policy_types } from "../../assets/conts";

const PolicyModal: FC<IProps> = (props) => {
    const uploadInput = useRef<HTMLInputElement | null>(null);

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex gap="small" vertical style={{ width: "calc(50% - 8px)" }}>
                    <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
                    <Select
                        id="company"
                        value={props.organizations.find(item => item.id.toString() === props.formik.values.company)?.name || undefined}
                        placeholder="Firma Seçiniz"
                        className={styles.input}
                        options={props.organizations.map(item => ({ value: item.id, label: item.name }))}
                        onChange={(value) => props.formik.setFieldValue("company", value)}
                    />
                    {props.formik.touched.company && props.formik.errors.company && (
                        <div className={styles.error}>{props.formik.errors.company}</div>
                    )}
                </Flex>
                <Flex gap="middle" justify="space-between">
                    <Flex gap="small" vertical style={{ width: "50%" }}>
                        <Typography className={styles.labelTitle}>Poliçe Bilgileri</Typography>
                        <Select
                            id="policyType"
                            value={props.formik.values.policyType || undefined}
                            placeholder="Poliçe Tipi"
                            options={Object.keys(policy_types).map(key => ({ value: key, label: policy_types[key] }))}
                            onChange={(value) => props.formik.setFieldValue("policyType", value)}
                        />
                        {props.formik.touched.policyType && props.formik.errors.policyType && (
                            <div className={styles.error}>{props.formik.errors.policyType}</div>
                        )}
                    </Flex>
                    <Flex className={styles.input} style={{ width: "50%" }} vertical justify="flex-end">
                        <Input
                            placeholder="Poliçe No"
                            {...props.formik.getFieldProps("policyNumber")}
                        />
                        {props.formik.touched.policyNumber && props.formik.errors.policyNumber && (
                            <div className={styles.error}>{props.formik.errors.policyNumber}</div>
                        )}
                    </Flex>
                </Flex>
                <Flex justify="space-between">
                    <Typography className={styles.uploadName}>{props.formik.values.uploadedFile?.name}</Typography>
                    <Flex vertical align="flex-end">
                        <input hidden ref={uploadInput} type="file" onChange={props.handleUploadFile} />
                        <Button className={styles.button} onClick={() => uploadInput.current?.click()}>
                            <PlusOutlined className={styles.buttonIcon} />
                            <div className={styles.buttonText}>Dosya Ekle</div>
                        </Button>
                        {props.formik.touched.uploadedFile && props.formik.errors.uploadedFile && (
                            <div className={styles.error}>{props.formik.errors.uploadedFile?.name}</div>
                        )}
                    </Flex>
                </Flex>
                {props.formik.values.policyType !== "nakliyat_sefer_poliçesi" &&
                    <Flex gap="small" vertical>
                        <Typography className={styles.labelTitle}>Tarih Aralıgı</Typography>
                        <Flex gap="middle">
                            <DatePicker
                                id="startDate"
                                name="startDate"
                                value={props.formik.values.startDate ? dayjs(props.formik.values.startDate, "YYYY-MM-DD") : null}
                                className={styles.input}
                                placeholder="Başlangıç Tarihi"
                                onChange={(date) => props.formik.setFieldValue("startDate", date.format("YYYY-MM-DD"))}
                            />
                            {props.formik.touched.startDate && props.formik.errors.startDate && (
                                <div className={styles.error}>{props.formik.errors.startDate}</div>
                            )}
                            <DatePicker
                                id="endDate"
                                name="endDate"
                                value={props.formik.values.endDate ? dayjs(props.formik.values.endDate, "YYYY-MM-DD") : null}
                                className={styles.input}
                                placeholder="Bitiş Tarihi"
                                onChange={(date) => props.formik.setFieldValue("endDate", date.format("YYYY-MM-DD"))}
                            />
                            {props.formik.touched.endDate && props.formik.errors.endDate && (
                                <div className={styles.error}>{props.formik.errors.endDate}</div>
                            )}
                        </Flex>
                    </Flex>
                }
                {props.type === "CREATE" &&
                    <Flex gap="small" align="flex-start">
                        <Checkbox
                            id="email_permission"
                            name="email_permission"
                            checked={props.formik.values.email_permission}
                            onChange={(e: any) => props.formik.setFieldValue("email_permission", e.target.checked)}
                        />
                        <div className={styles.mailCheckbox}>
                            Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Poliçe Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
                        </div>
                    </Flex>
                }
            </Flex>
        </ModalDialog>
    );
}

export default PolicyModal;
