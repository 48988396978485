import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    organization: Yup
        .string()
        .required("Firma zorunludur."),
    first_name: Yup
        .string()
        .required("İsim zorunludur."),
    last_name: Yup
        .string()
        .required("Soyisim zorunludur."),
    email: Yup
        .string()
        .required("Email zorunludur."),
    role: Yup
        .string()
        .required("Rol zorunludur."),
});

export default validationSchema;
