import * as Yup from "yup";

const validationSchemaForCreate = Yup.object().shape({
    company: Yup
        .string()
        .required('Firma zorunludur'),
    expedition_date: Yup
        .string()
        .required('Sevk Tarihi zorunludur'),
});

const validationSchemaForEdit = Yup.object().shape({
    company: Yup
        .string()
        .required('Firma zorunludur'),
    policy_number: Yup
        .string()
        .required('Poliçe No zorunludur'),
    expedition_date: Yup
        .string()
        .required('Sevk Tarihi zorunludur'),
});

export { validationSchemaForCreate, validationSchemaForEdit };