import { FC, useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import { Layout } from '../';
import { getToken } from '../../helpers/Auth';
import { useAppDispatch } from '../../store';
import { tableActions } from '../../store/reducers/tableReducer';

const PrivateRoute: FC = () => {
  const location = useLocation();
  const token = getToken();
  const dispatch = useAppDispatch();

  useEffect(() => {
    dispatch(tableActions.clearTable());
  }, [location.pathname, dispatch]);

  return !!token.length
    ? <Layout><Outlet /></Layout>
    : <Navigate to="/login" replace state={{ from: location }} />;
}

export default PrivateRoute;