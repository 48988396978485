import { ItemType, MenuItemType } from "antd/es/menu/interface";
import HouseSolid from "../../assets/icons/house-solid-w.svg";
import TruckSolid from "../../assets/icons/truck-solid.svg";
import CarSolid from "../../assets/icons/car-solid.svg";
import NotesMedicalSolid from "../../assets/icons/notes-medical-solid.svg";
import CarBurstSolid from "../../assets/icons/car-burst-solid.svg";
import CalculatorSolid from "../../assets/icons/calculator-solid.svg";
import SitemapSolidB from "../../assets/icons/sitemap-solid-b.svg";
import UserGearSolid from "../../assets/icons/user-gear-solid.svg";

export const items: ItemType<MenuItemType>[] = [
    {
        key: "/",
        icon: <img src={HouseSolid} alt="HouseSolid" />,
        label: "Ana Sayfa",
    },
    {
        key: "10",
        label: "Ana Poliçeler",
        icon: <img src={SitemapSolidB} alt="SitemapSolidB" />,
        children: [
            {
                key: "/main-insurances/domestic-insurance",
                label: "Yurtiçi Poliçeler",
            },
            {
                key: "/main-insurances/abroad-insurance",
                label: "Yurtdışı Poliçeler",
            },
        ]
    },
    {
        key: "/motor-insurance",
        icon: <img src={CarSolid} alt="CarSolid" />,
        label: "Oto Poliçeler",
        //TODO: no children
        // children: [
        //     {
        //         key: "6",
        //         label: "Poliçe Görüntüleme",
        //     },
        //     {
        //         key: "7",
        //         label: "Yeni Poliçe Talebi Oluşturma",
        //     }
        // ]
    },
    {
        key: "Nakliyat Sigortaları",
        icon: <img src={TruckSolid} alt="TruckSolid" />,
        label: "Nakliyat",
        children: [
            {
                key: "/freight-insurance/policy",
                label: "Nakliyat Poliçeleri",
            },
            {
                key: "/freight-insurance/expedition-certificate",
                label: "Abonman Sefer Sertifikaları",
            }
        ]
    },
    
    {
        key: "8",
        label: "Sağlık",
        icon: <img src={NotesMedicalSolid} alt="NotesMedicalSolid" />,
        children: [
            {
                key: "/grup-saglik", //todo: change this
                label: "Grup Sağlık",
            },
            {
                key: "/seyahat-saglik", //todo: change this
                label: "Seyahat Sağlık",
            }
        ]
    },
    {
        key: "/damage-management",
        label: "Hasar",
        icon: <img src={CarBurstSolid} alt="CarBurstSolid" />
    },
    {
        key: "12",
        label: "Muhasebe",
        icon: <img src={CalculatorSolid} alt="CalculatorSolid" />
    },
    {
        key: "Ayarlar",
        label: "Ayarlar",
        icon: <img src={UserGearSolid} alt="UserGearSolid" />,
        children: [
            {
                key: "/firmalar", //todo
                label: "Firmalar",
            },
            {
                key: "/user-profiles-and-authorisation/user-settings",
                label: "Kullanıcılar",
            },
            {
                key: "/user-profiles-and-authorisation/profile-settings",
                label: "Profiller",
            }
        ]
    },
   
];