import React from "react";
import { Table, Input, TableColumnsType, Tag } from "antd";
import { ColumnType, ColumnsType, TablePaginationConfig } from "antd/es/table";
import FilterSolid from "../../assets/icons/filter-solid-b.svg";
import { useAppDispatch, useAppSelector } from "../../store";
import { IProps } from "./type";
import styles from "./index.module.scss";
import { tableActions } from "../../store/reducers/tableReducer";
import { FilterValue } from "antd/es/table/interface";
import { status_colors, statuses } from "../../assets/conts";

const DataTable = <T extends object>({ dataSource, columns, scrollable, expandedRows, isPaginationDisplayed = true }: IProps<T>) => {
    const dispatch = useAppDispatch();
    const row = useAppSelector(state => state.tableReducer.row);
    const total = useAppSelector(state => state.tableReducer.total);
    const page = useAppSelector(state => state.tableReducer.page);

    const locale = {
        triggerAsc: "Sıralamak için tıklayın",
        triggerDesc: "Sıralamak için tıklayın",
        cancelSort: "Sıralamak için tıklayın",
    };

    const handleSearch = (selectedKeys: React.Key[], dataIndex: string) => {
        dispatch(tableActions.changeTableSearch({
            key: dataIndex,
            value: selectedKeys[0] as string
        }));
    };

    const handleTableChange = (
        pagination: TablePaginationConfig,
        filters: Record<string, FilterValue | null>,
        sorter: any
    ) => {
        if (!sorter.order) dispatch(tableActions.clearTableSort());
        else dispatch(tableActions.changeTableSort({ key: sorter.field?.toString() || "", order: sorter.order }));

        pagination.current && dispatch(tableActions.changeTablePage({ size: pagination.pageSize || 10, number: pagination.current - 1 || 0 }));
    };

    const getColumnSearchProps = (column: ColumnType<T> & { searchable?: boolean | undefined }): ColumnsType<T>[number] => ({
        filterDropdown: ({ setSelectedKeys, selectedKeys }) => (
            <div className={styles.inputContainer}>
                <Input
                    placeholder={`${column.title} ara`}
                    value={selectedKeys[0]}
                    onChange={e => setSelectedKeys(e.target.value ? [e.target.value] : [])}
                    onPressEnter={() => handleSearch(selectedKeys, column.dataIndex?.toString() || "")}
                />
            </div>
        ),
        filterIcon: <img src={FilterSolid} alt="FilterSolid" />,
        //onFilterDropdownOpenChange: visible => !visible && handleSearch([""], "")
    });

    const enhancedColumns = columns.map(col => {
        if (col.dataIndex) {
            return {
                ...col,
                ...(col.searchable ? getColumnSearchProps(col) : {}),
            };
        }
        return col;
    });

    interface ExpandedDataType {
        created_by: string;
        created_at_date: string;
        created_at_time: string;
        status: string | JSX.Element;
    }

    const expandedRowRender = () => {
        const columns: TableColumnsType<ExpandedDataType> = [
            {
                title: "İşlem Sahibi",
                dataIndex: "created_by",
                key: "created_by",
                sorter: (a, b) => a.created_by.localeCompare(b.created_by)
            },
            {
                title: "İşlem Tarihi",
                dataIndex: "created_at_date",
                key: "created_at_date",
                sorter: (a, b) => new Date(a.created_at_date).getTime() - new Date(b.created_at_date).getTime()
            },
            {
                title: "İşlem Saati",
                dataIndex: "created_at_time",
                key: "created_at_time",
                sorter: (a, b) => a.created_at_time.localeCompare(b.created_at_time)
            },
            {
                title: "İşlem Detayı",
                dataIndex: "status",
                key: "status",
                render: (value) => <Tag className={styles.tag} color={status_colors[value]}>{statuses[value]}</Tag>,
            }
        ];

        return (
            <Table
                locale={locale}
                rootClassName={styles.subTable}
                columns={columns}
                dataSource={expandedRows}
                pagination={false}
            />
        );
    };

    return (
        <>
            <Table
                rootClassName={styles.table}
                dataSource={dataSource}
                columns={enhancedColumns}
                locale={locale}
                scroll={{ x: scrollable ? 'max-content' : undefined }}
                expandable={{
                    expandedRowRender,
                    expandedRowKeys: row !== 0 ? [row] : [],
                    expandIcon: () => null,
                    showExpandColumn: false
                }}
                pagination={isPaginationDisplayed ? {
                    total,
                    pageSize: page.size,
                    current: page.number + 1,
                    showSizeChanger: true,
                    pageSizeOptions: ['10', '20', '50', '100']
        
                } : false}
                onChange={handleTableChange}
            />
        </>
    );
};

export default DataTable;
