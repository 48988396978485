import { FC, useEffect, useState } from "react";
import { PlusOutlined } from "@ant-design/icons";
import { Button, Checkbox, DatePicker } from "antd";
import { BindParentModal, DataTable, RemoveParentModal, SummarySheetModal } from "../../components";
import { tableActions } from "../../store/reducers/tableReducer";
import {
  IDataSource,
  IInitialValues,
  IOrganization,
  IParentItem,
  IParentResponse,
  IResponse,
  ISummaryTableItem
} from "./type";
import styles from "./index.module.scss";
import { useFormik } from "formik";
import { DamageNotificationModal } from "../../components";
import { INITIAL_VALUES } from "./const";
import { useAppDispatch, useAppSelector } from "../../store";
import useHttpService from "../../services/hook";
import { getColumns } from "./columns";
import validationSchema from "./validations";
import { RequestMethods } from "../../services/enum";
import {
  cleanupAfterSubmit,
  prepareFetchParameters,
  prepareFormData,
  prepareModalData
} from "./helpers";
import { IPage, ISearch, ISort } from "../../store/reducers/tableReducer/type";
import { IColumn, IExpandedRow } from "../../components/DataTable/type";

const { RangePicker } = DatePicker;

const DamageManagement: FC = () => {
  const search = useAppSelector(state => state.tableReducer.search);
  const sort = useAppSelector(state => state.tableReducer.sort);
  const page = useAppSelector(state => state.tableReducer.page);
  const rows = useAppSelector(state => state.tableReducer.rows);
  const dispatch = useAppDispatch();
  const executeService = useHttpService();

  // State
  const [selectedItem, setSelectedItem] = useState<IDataSource | undefined>(undefined);
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [summaryTable, setSummaryTable] = useState<ISummaryTableItem[]>([]);
  const [dataSource, setDataSource] = useState<IDataSource[]>([]);
  const [expandedRows, setExpandedRows] = useState<IExpandedRow[]>([]);
  const [dates, setDates] = useState<string[]>([]);
  const [isOpenBindModal, setIsOpenBindModal] = useState<boolean>(false);
  const [selectedRecord, setSelectedRecord] = useState<IDataSource | undefined>(undefined);
  const [parentList, setParentList] = useState<IParentItem[]>([]);
  const [parentItem, setParentItem] = useState<IParentItem[]>([]);
  const [organizations, setOrganizations] = useState<IOrganization[]>([]);

  // Const
  const BIND_COLUMN: IColumn<IDataSource> = {
    title: "",
    key: "select",
    dataIndex: "select",
    render: (_, record) => <Checkbox disabled={selectedRecord ? record.recent_status !== selectedRecord?.recent_status || record.company !== selectedRecord.company : false} onClick={(e) => handleClickCheckbox(e, record)} />,
  }

  // Handlers
  const handleFetchOrganizations = async () => {
    const response = await executeService<IOrganization[]>({ method: RequestMethods.GET, url: `auth/organization/` })
    if (response && Array.isArray(response)) {
      setOrganizations(response);
    }
  }

  const handleFetchData = async (dates?: string[], search?: ISearch, sort?: ISort, page?: IPage) => {
    const parameters = prepareFetchParameters(dates, search, sort, page);
    const response = await executeService<IResponse>({ method: RequestMethods.GET, url: `damage-management/damage-management/${parameters}` })
    if (response && "results" in response) {
      dispatch(tableActions.changeTableTotal(response.count));
      const results = response.results.reduce<IDataSource[]>((prev: IDataSource[], cur: IDataSource) => {
        return [...prev, { ...cur, key: cur.id }];
      }, []);
      setDataSource(results);
    }
  }

  const handleClickEye = async (id: number) => {
    dispatch(tableActions.changeTableRow(id));
    const expandedRows = await executeService<IExpandedRow[]>({ method: RequestMethods.GET, url: `damage-management/damage-management/${id}/get-latest-processes/` });
    expandedRows && Array.isArray(expandedRows) && setExpandedRows(expandedRows);
  }

  const handleOpenSummaryTable = async () => {
    const summaryTable = await executeService<ISummaryTableItem[]>({ method: RequestMethods.GET, url: "damage-management/parent/get-summary-table/" });
    Array.isArray(summaryTable) && setSummaryTable(summaryTable);
  }

  const handleSaveSummaryTable = () => setSummaryTable([]);

  const handleCancelSummaryTable = () => setSummaryTable([]);

  const handleOpenModal = () => setIsModalOpen(true);

  const handleCloseModal = () => {
    setIsModalOpen(false);
    formik.setErrors({});
    formik.setTouched({});
  }

  const handleOpenEditModal = async (id: number) => {
    const selectedItem = await executeService<IDataSource>({ method: RequestMethods.GET, url: `damage-management/damage-management/${id}/` });
    selectedItem && "id" in selectedItem && setSelectedItem(selectedItem);
  };

  const handleCloseEditModal = () => {
    setSelectedItem(undefined);
    formik.setErrors({});
    formik.setTouched({});
  };

  const handleUploadFile = (e: any) => formik.setFieldValue("upload_files", e.target.files);

  const handleClickCancel = async (id: number) => {
    try {
      const formData = new FormData();
      formData.append("id", id.toString());
      formData.append("status", "cancel_expected");
      await executeService<unknown>({
        method: RequestMethods.POST,
        url: `damage-management/damage-management/${id}/update-status/`,
        data: formData,
        contentType: "multipart/form-data"
      });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(setIsModalOpen, setSelectedItem, formik.resetForm, handleFetchData);
    }
  }

  const handleClickDelete = async (id: number) => {
    try {
      await executeService<unknown>({ method: RequestMethods.DELETE, url: `damage-management/damage-management/${id}/` });
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(setIsModalOpen, setSelectedItem, formik.resetForm, handleFetchData);
    }
  }

  const handleClickBindDamage = async () => setIsOpenBindModal(prev => !prev);

  const handleClickCheckbox = (e: any, record: IDataSource) => {
    if (!rows.length && e.target.checked) setSelectedRecord(record);
    else if (rows.length === 1 && !e.target.checked) setSelectedRecord(undefined);
    dispatch(tableActions.changeTableRows({ id: record.id, propertyKey: "select", propertyValue: e.target.checked }));
  }

  const handleClickBindSave = async () => {
    try {
      await executeService<IDataSource>({
        method: RequestMethods.POST,
        url: "damage-management/parent/",
        data: { damage_managements: rows.map(row => row.id) }
      });
    } catch (e) {
      console.log(e);
    } finally {
      dispatch(tableActions.clearTableRows());
      dispatch(tableActions.changeTablePage({ number: 0, size: 10 }));
      handleFetchData();
      setIsOpenBindModal(false);
      setSelectedRecord(undefined);
    }
  }

  const handleClickBindCancel = () => {
    dispatch(tableActions.clearTableRows());
    setIsOpenBindModal(false);
    setSelectedRecord(undefined);
  }

  const handleClickRemoveParent = async (parent: number, id: number) => {
    const response = await executeService<IParentResponse>({
      method: RequestMethods.GET,
      url: `damage-management/parent/${parent}/`
    });

    if (response && "id" in response) {
      setParentList(response.damage_managements.filter(dm => dm.id !== id));
      setParentItem(response.damage_managements.filter(dm => dm.id === id));
    }
  }

  const handleClickRemoveCancel = () => {
    setParentItem([]);
    setParentList([]);
  }

  const handleClickRemoveSave = async () => {
    const removedItem = dataSource.find(item => item.id === parentItem[0].id);
    await executeService<unknown>({
      method: RequestMethods.PATCH,
      url: `damage-management/damage-management/${parentItem[0].id}/`,
      data: { ...removedItem, parent: null }
    });
    setParentItem([]);
    setParentList([]);
    handleFetchData();
  }

  const handleSubmit = async (values: IInitialValues) => {
    try {
      const formData = prepareFormData(values);

      if (selectedItem) {
        const statusFormData = new FormData();
        statusFormData.append("status", values.recent_status)
        await executeService<IDataSource>({
          method: RequestMethods.PUT,
          url: `damage-management/damage-management/${selectedItem.id}/`,
          data: formData,
          contentType: "multipart/form-data"
        });
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: `damage-management/damage-management/${selectedItem.id}/update-status/`,
          data: statusFormData,
          contentType: "multipart/form-data"
        });
      } else {
        await executeService<IDataSource>({
          method: RequestMethods.POST,
          url: "damage-management/damage-management/",
          data: formData,
          contentType: "multipart/form-data"
        });
      }
    } catch (e) {
      console.log(e);
    } finally {
      cleanupAfterSubmit(setIsModalOpen, setSelectedItem, formik.resetForm, handleFetchData);
      dispatch(tableActions.changeTablePage({ number: 0, size: 20 }));
    }
  };

  // Columns
  const columns = getColumns(handleClickEye, handleClickDelete, handleClickCancel, handleOpenEditModal, handleClickRemoveParent, organizations);

  // Form Management
  const formik = useFormik<IInitialValues>({ initialValues: INITIAL_VALUES, validationSchema, onSubmit: handleSubmit });

  //Effects
  useEffect(() => {
    handleFetchOrganizations();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const result = prepareModalData(selectedItem);
      result && formik.setValues(result);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  useEffect(() => {
    handleFetchData(dates, search, sort, page);
  }, [dates, search.key, search.value, sort.key, sort.order, page.number, page.size]);

  // JSX Return
  return (
    <div className={styles.container}>
      <div className={styles.subHeader}>
        <div className={styles.leftLine} />
        <div className={styles.pageName}>Hasar Yönetimi</div>
        <div className={styles.rightLine} />
      </div>
      <div className={styles.tableContainer}>
        <div className={styles.rowContainer}>
          <RangePicker
            placeholder={["Başlangıç Tarihi", "Bitiş Tarihi"]}
            className={styles.datePicker}
            onChange={(_, dates: [string, string]) => setDates(dates)}
          />
          <div className={styles.buttonContainer}>
            <Button className={styles.bindButton} onClick={handleClickBindDamage}>
              <div className={styles.buttonText}>Dosya Bağlama</div>
            </Button>
            <Button className={styles.displayButton} onClick={handleOpenSummaryTable}>
              <div className={styles.buttonText}>İcmal Tablosunu Görüntüle</div>
            </Button>
            <Button className={styles.addButton} onClick={handleOpenModal}>
              <PlusOutlined className={styles.buttonIcon} />
              <div className={styles.buttonText}>Yeni Hasar Bildirimi</div>
            </Button>
          </div>
        </div>
        <DataTable<IDataSource>
          columns={!isOpenBindModal ? [BIND_COLUMN, ...columns] : columns}
          dataSource={dataSource}
          scrollable
          expandedRows={expandedRows}
        />
      </div>
      <SummarySheetModal
        open={!!summaryTable.length}
        title="İcmal Tablosu"
        subTitle="İcmal Tablosu"
        summaryTable={summaryTable}
        handleSubmit={handleSaveSummaryTable}
        handleCancel={handleCancelSummaryTable}
      />
      <DamageNotificationModal
        type="CREATE"
        open={isModalOpen}
        title="Yeni Hasar Bildirimi Oluşturma"
        subTitle="Yeni Hasar Bildirimi Oluşturma"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
      />
      <DamageNotificationModal
        type="EDIT"
        open={!!selectedItem}
        title="Hasar Bildirimi Güncelleme"
        subTitle="Hasar Bildirimi Güncelleme"
        formik={formik}
        organizations={organizations}
        handleUploadFile={handleUploadFile}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEditModal}
      />
      <BindParentModal
        open={isOpenBindModal}
        title="Dosya Bağlama"
        subTitle="Dosya Bağlama"
        dataSource={dataSource.filter(ds => rows.map(row => row.id).includes(ds.id))}
        handleCancel={handleClickBindCancel}
        handleSubmit={handleClickBindSave}
      />
      <RemoveParentModal
        open={!!parentItem.length && !!parentList.length}
        title="Bağlı Dosya Ayırma"
        subTitle="Bağlı Dosya Ayırma"
        item={parentItem}
        list={parentList}
        handleCancel={handleClickRemoveCancel}
        handleSubmit={handleClickRemoveSave}
      />
    </div>
  );
}

export default DamageManagement;
