import { IResponse } from "../../pages/Login/type";

const login = (response: IResponse) => {
    localStorage.setItem('access', response.access);
    localStorage.setItem('refresh', response.refresh);
    window.location.replace("/");
};

const logout = () => {
    localStorage.removeItem('access');
    localStorage.removeItem('refresh');
    window.location.replace("/login");
};

const getToken = (): string => {
    const token = localStorage.getItem('access');
    return token || "";
};

export { 
    login, 
    logout, 
    getToken 
};