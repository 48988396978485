import { FC, useRef } from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Flex,
    Input,
    Select,
    Typography
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ModalDialog } from "..";
import { IProps } from "./type";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import { motor_insurance_branches, statuses } from "../../assets/conts";

const MotorInsuranceModal: FC<IProps> = (props) => {
    const { getFieldProps, setFieldValue, values, errors, touched } = props.formik;
    const uploadInput = useRef<HTMLInputElement | null>(null);

    const handleChangeCheckbox = (e: any, field: string) => {
        setFieldValue("branch", e.target.checked ? [...values.branch, field] : values.branch.filter(item => item !== field));
    }

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex justify="space-between">
                    <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
                    {props.type === "CANCEL" ? 
                    <Typography className={styles.labelValue}>{props.organizations.find(item => item.id.toString() === props.formik.values.company)?.name}</Typography>
                    :
                    <Select
                        id="company"
                        value={props.organizations.find(item => item.id.toString() === props.formik.values.company)?.name || undefined}
                        placeholder="Firma Seçiniz"
                        className={styles.input}
                        options={props.organizations.map(item => ({ label: item.name, value: item.id }))}
                        onChange={(value) => props.formik.setFieldValue("company", value)}
                    />}
                </Flex>
                {props.type === "CREATE" ? (
                    <Flex gap="small" vertical>
                        <Typography className={styles.labelTitle}>Poliçe Bilgileri</Typography>
                        <Flex justify="space-between">
                            <Checkbox
                                checked={values.branch.includes("insurance")}
                                onChange={(e: any) => handleChangeCheckbox(e, "insurance")}
                            >
                                Kasko
                            </Checkbox>
                            <Checkbox
                                checked={values.branch.includes("traffic")}
                                onChange={(e: any) => handleChangeCheckbox(e, "traffic")}
                            >
                                Trafik
                            </Checkbox>
                            <Checkbox
                                checked={values.branch.includes("imm")}
                                onChange={(e: any) => handleChangeCheckbox(e, "imm")}
                            >
                                İMM
                            </Checkbox>
                            <Checkbox
                                checked={values.branch.includes("green_card")}
                                onChange={(e: any) => handleChangeCheckbox(e, "green_card")}
                            >
                                Yeşil Kart (Yurtdışı)
                            </Checkbox>
                        </Flex>
                    </Flex>
                ) : (
                    <Flex justify="space-between">
                        <Typography className={styles.labelTitle}>Poliçe Tipi</Typography>
                        <Typography className={styles.labelValue}>{motor_insurance_branches[values.branch[0]]}</Typography>
                    </Flex>
                )}
                <Flex gap="small" vertical>
                    {props.type === "CANCEL" ?
                        <>
                            <Typography className={styles.labelTitle}>Gerekli Belgeler</Typography>
                            <Typography className={styles.documentDescription}>
                                * Oto sigortaları sadece aracın satılması halinde bitiş tarihinden önce revize edilebilmektedir. Poliçe iptali için lütfen noter satış belgesini yükleyiniz. Satılan araca ait kasko ve trafik sigortası poliçelerinizin iptali sağlanacaktır. Satılan aracın yerine yeni bir araç satın aldıysanız yeni poliçe talebi menüsünden talebinizi ayrıca iletiniz. İptal talebi ile birlikte yeni poliçe talebi iletmeniz halinde kasko poliçeniz için iptal yapılmadan araç değişikliği zeyilnamesi düzenlenebilecektir.
                            </Typography>
                        </>
                        :
                        <>
                            <Typography className={styles.labelTitle}>Gerekli Belgeler</Typography>
                            <Typography className={styles.documentDescription}>
                                * Mevcut araçlarınız veya ikinci el olarak satın alınan araçlarınız için belge seri numarası görünecek şekilde araç ruhsatı ekleyiniz.
                            </Typography>
                            <Typography className={styles.documentDescription}>
                                * Sıfır araçlarınız için araç faturasını ekleyiniz ve açıklama alanında aracın il plaka kodunu belirtiniz.
                            </Typography>
                        </>}
                </Flex>
                <Flex justify="space-between">
                    <Typography className={styles.uploadName}>
                        {values.upload_files?.[0]?.name || "Ek Dosya"}
                    </Typography>
                    <Flex vertical align="flex-end">
                        <input hidden ref={uploadInput} type="file" onChange={props.handleUploadFile} />
                        <Button className={styles.button} onClick={() => uploadInput.current?.click()}>
                            <PlusOutlined className={styles.buttonIcon} />
                            <div className={styles.buttonText}>Dosya Ekle</div>
                        </Button>
                    </Flex>
                </Flex>
                <Flex gap="small" vertical>
                    <Typography className={styles.labelTitle}>Açıklama</Typography>
                    <Input.TextArea
                        placeholder="Açıklama"
                        {...getFieldProps("description")}
                    />
                    {touched.description && errors.description && (
                        <div className={styles.error}>{errors.description}</div>
                    )}
                </Flex>
                {props.type === "EDIT" &&
                    <Flex justify="space-between">
                        <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
                        <Select
                            id="recent_status"
                            value={props.formik.values.recent_status}
                            style={{ width: 175 }}
                            className={styles[`statusDropdown-${props.formik.values.recent_status}`]}
                            options={Object.keys(statuses).map(key => ({ label: statuses[key], value: key }))}
                            onChange={(value) => props.formik.setFieldValue("recent_status", value)}
                        />
                    </Flex>
                }
                {props.type !== "CANCEL" &&
                    <Flex gap="small" vertical>
                        <Typography className={styles.labelTitle}>Tarih Aralıgı</Typography>
                        <Flex justify="space-between">
                            <DatePicker
                                id="start_date"
                                name="start_date"
                                value={values.start_date ? dayjs(values.start_date, "YYYY-MM-DD") : null}
                                className={styles.datePicker}
                                placeholder="Başlangıç Tarihi"
                                onChange={(date) => setFieldValue("start_date", date.format("YYYY-MM-DD"))}
                            />
                            {touched.start_date && errors.start_date && (
                                <div className={styles.error}>{errors.start_date}</div>
                            )}
                            <DatePicker
                                id="end_date"
                                name="end_date"
                                value={values.end_date ? dayjs(values.end_date, "YYYY-MM-DD") : null}
                                className={styles.datePicker}
                                placeholder="Bitiş Tarihi"
                                onChange={(date) => setFieldValue("end_date", date.format("YYYY-MM-DD"))}
                            />
                            {touched.end_date && errors.end_date && (
                                <div className={styles.error}>{errors.end_date}</div>
                            )}
                        </Flex>
                    </Flex>
                }
                {props.type === "EDIT" &&
                    <Flex gap="small" align="flex-start">
                        <Checkbox
                            id="email_permission"
                            name="email_permission"
                            checked={props.formik.values.email_permission}
                            onChange={(e: any) => props.formik.setFieldValue("email_permission", e.target.checked)}
                        />
                        <div className={styles.mailCheckbox}>
                            Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Poliçe Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
                        </div>
                    </Flex>
                }
            </Flex>
        </ModalDialog>
    );
};

export default MotorInsuranceModal;
