import { statuses } from "../../assets/conts";
import { ISummaryTableItem } from "../../pages/DamageManagement/type";
import { IDataItem } from "./type";

export const transformToData = (items: ISummaryTableItem[]): IDataItem[] => {
    const groupedByStatus: { [key: string]: IDataItem } = {};

    items.forEach(item => {
        if (!groupedByStatus[item.recent_status]) {
            groupedByStatus[item.recent_status] = {
                key: (Object.keys(groupedByStatus).length + 1).toString(),
                status: statuses[item.recent_status],
                domestic: 0,
                transportation: 0,
                abroad: 0,
                total: 0,
            };
        }

        if (item.damage_type === 'domestic') {
            groupedByStatus[item.recent_status].domestic += item.count;
        } else if (item.damage_type === 'transportation') {
            groupedByStatus[item.recent_status].transportation += item.count;
        } else if (item.damage_type === 'abroad') {
            groupedByStatus[item.recent_status].abroad += item.count;
        }

        groupedByStatus[item.recent_status].total += item.count;
    });

    const totalRow: IDataItem = {
        key: (Object.keys(groupedByStatus).length + 1).toString(),
        status: "Toplam",
        domestic: 0,
        transportation: 0,
        abroad: 0,
        total: 0,
    };

    Object.values(groupedByStatus).forEach(item => {
        totalRow.domestic += item.domestic;
        totalRow.transportation += item.transportation;
        totalRow.abroad += item.abroad;
        totalRow.total += item.total;
    });

    return [...Object.values(groupedByStatus), totalRow];
};