import { IColumn } from "../../../components/DataTable/type";
import { IDataSource, IOrganization } from "./type";
import styles from "./index.module.scss";
import { Icon } from "../../../components";
import TrashSolidBlack from "../../../assets/icons/trash-solid-b-black.svg";
import PenToSquareSolidBlack from "../../../assets/icons/pen-to-square-solid-w-black.svg";
import FilePdfSolidBlack from "../../../assets/icons/file-pdf-solid-black.svg";
import { Popconfirm, Tag } from "antd";
import { downloadFile } from "../../../helpers/File";
import { policy_types } from "../../../assets/conts";

export const getColumns = (
    handleClickDelete: (id: number) => Promise<void>,
    handleOpenEditModal: (id: number) => Promise<void>,
    organizations: IOrganization[]
): IColumn<IDataSource>[] => [
        {
            title: "Firma",
            dataIndex: "company",
            key: "company",
            searchable: true,
            sorter: (a, b) => a.company - b.company,
            render: (_, record) => <div>{organizations.find(item => item.id.toString() === record.company)?.name}</div>
        },
        {
            title: "Poliçe Tipi",
            dataIndex: "policy_type",
            key: "policy_type",
            searchable: true,
            sorter: (a, b) => a.policy_type - b.policy_type,
            render: (_: any, record: IDataSource) =>
                <Tag className={styles[`${record.policy_type}Tag`]}>{policy_types[record.policy_type]}</Tag>
        },
        {
            title: "Poliçe No",
            dataIndex: "policy_number",
            key: "policy_number",
            sorter: (a, b) => a.policy_number - b.policy_number
        },
        {
            title: "Başlangıç Tarihi",
            dataIndex: "start_date",
            key: "start_date",
            sorter: (a, b) => a.start_date - b.start_date
        },
        {
            title: "Bitiş Tarihi",
            dataIndex: "end_date",
            key: "end_date",
            sorter: (a, b) => a.end_date - b.end_date
        },
        {
            title: "Ek Dosya",
            dataIndex: "attached_file",
            key: "attached_file",
            render: (_: any, record: IDataSource) => {

                if (!!record.additional_files.length) return <Popconfirm
                    title={<div className={styles.popconfirmTitle}>Ek dosyayı indirmek istiyor musunuz?</div>}
                    overlayInnerStyle={{
                        width: 150,
                        borderColor: "#E59500",
                        borderWidth: 1,
                        borderStyle: "solid"
                    }}
                    okText="İndir"
                    cancelText="İptal"
                    icon={null}
                    color="#FAFAFA"
                    okButtonProps={{ style: { backgroundColor: "#E59500", width: "45%" } }}
                    cancelButtonProps={{ style: { color: "#E59500", borderColor: "#E59500", width: "45%" } }}
                    onConfirm={() => downloadFile(record.additional_files[record.additional_files.length - 1].file)}
                >
                    <Icon source={FilePdfSolidBlack} />
                </Popconfirm>  
                else return <></>
            }
        },
        {
            title: "İşlem",
            dataIndex: "action",
            key: "action",
            render: (_: any, record: IDataSource) => (
                <div className={styles.actionRowContainer}>
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>Silme işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => handleClickDelete(record.id)}
                    >
                        <Icon source={TrashSolidBlack} />
                    </Popconfirm>
                    <Icon
                        source={PenToSquareSolidBlack}
                        onClick={() => handleOpenEditModal(record.id)}
                    />
                </div>
            ),
        }
    ];
