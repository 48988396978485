import { FC, useRef } from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Flex,
    Input,
    Select,
    Typography
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ModalDialog } from "..";
import { IProps } from "./type";
import styles from "./index.module.scss";
import dayjs from "dayjs";
import {
    domestic_insurance_policy_types,
    domestic_insurance_branches,
    statuses
} from "../../assets/conts";

const DomesticInsuranceModal: FC<IProps> = (props) => {
    const uploadInput = useRef<HTMLInputElement | null>(null);

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex gap="small" vertical style={{ width: "calc(50% - 8px)" }}>
                    <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
                    <Select
                        id="company"
                        value={props.organizations.find(item => item.id.toString() === props.formik.values.company)?.name || undefined}
                        placeholder="Firma Seçiniz"
                        className={styles.input}
                        options={props.organizations.map(item => ({ value: item.id, label: item.name }))}
                        onChange={(value) => props.formik.setFieldValue("company", value)}
                    />
                    {props.formik.touched.company && props.formik.errors.company && (
                        <div className={styles.error}>{props.formik.errors.company}</div>
                    )}
                </Flex>
                <Flex gap="middle" justify="space-between">
                    <Flex gap="small" vertical style={{ width: "33%" }}>
                        <Typography className={styles.labelTitle}>Poliçe Bilgileri</Typography>
                        <Select
                            id="type"
                            value={props.formik.values.type || undefined}
                            placeholder="Poliçe Tipi"
                            options={Object.keys(domestic_insurance_policy_types).map(key => ({ value: key, label: domestic_insurance_policy_types[key] }))}
                            onChange={(value) => props.formik.setFieldValue("type", value)}
                        />
                        {props.formik.touched.type && props.formik.errors.type && (
                            <div className={styles.error}>{props.formik.errors.type}</div>
                        )}
                    </Flex>
                    <Flex className={styles.input} style={{ width: "33%" }} vertical justify="flex-end">
                        <Input
                            placeholder="Poliçe No"
                            {...props.formik.getFieldProps("policy_number")}
                        />
                        {props.formik.touched.policy_number && props.formik.errors.policy_number && (
                            <div className={styles.error}>{props.formik.errors.policy_number}</div>
                        )}
                    </Flex>
                    <Flex gap="small" vertical justify="flex-end" style={{ width: "33%" }}>
                        <Select
                            id="branch"
                            value={props.formik.values.branch || undefined}
                            placeholder="Branş"
                            options={Object.keys(domestic_insurance_branches).map(key => ({ value: key, label: domestic_insurance_branches[key] }))}
                            onChange={(value) => props.formik.setFieldValue("branch", value)}
                        />
                        {props.formik.touched.branch && props.formik.errors.branch && (
                            <div className={styles.error}>{props.formik.errors.branch}</div>
                        )}
                    </Flex>
                </Flex>
                <Flex justify="space-between">
                    <Typography className={styles.uploadName}>{props.formik.values.upload_files?.[0]?.name || "Ek Dosya"}</Typography>
                    <Flex vertical align="flex-end">
                        <input hidden ref={uploadInput} type="file" onChange={props.handleUploadFile} />
                        <Button className={styles.button} onClick={() => uploadInput.current?.click()}>
                            <PlusOutlined className={styles.buttonIcon} />
                            <div className={styles.buttonText}>Dosya Ekle</div>
                        </Button>
                    </Flex>
                </Flex>
                <Flex gap="small" vertical>
                    <Typography className={styles.labelTitle}>Tarih Aralıgı</Typography>
                    <Flex gap="middle">
                        <DatePicker
                            id="start_date"
                            name="start_date"
                            value={props.formik.values.start_date ? dayjs(props.formik.values.start_date, "YYYY-MM-DD") : null}
                            className={styles.input}
                            placeholder="Başlangıç Tarihi"
                            onChange={(date) => props.formik.setFieldValue("start_date", date.format("YYYY-MM-DD"))}
                        />
                        {props.formik.touched.start_date && props.formik.errors.start_date && (
                            <div className={styles.error}>{props.formik.errors.start_date}</div>
                        )}
                        <DatePicker
                            id="end_date"
                            name="end_date"
                            value={props.formik.values.end_date ? dayjs(props.formik.values.end_date, "YYYY-MM-DD") : null}
                            className={styles.input}
                            placeholder="Bitiş Tarihi"
                            onChange={(date) => props.formik.setFieldValue("end_date", date.format("YYYY-MM-DD"))}
                        />
                        {props.formik.touched.end_date && props.formik.errors.end_date && (
                            <div className={styles.error}>{props.formik.errors.end_date}</div>
                        )}
                    </Flex>
                </Flex>
                {props.type === "EDIT" &&
                    <Flex justify="space-between">
                        <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
                        <Select
                            id="recent_status"
                            value={props.formik.values.recent_status}
                            style={{ width: 175 }}
                            className={styles[`statusDropdown-${props.formik.values.recent_status}`]}
                            options={Object.keys(statuses).map(key => ({ label: statuses[key], value: key }))}
                            onChange={(value) => props.formik.setFieldValue("recent_status", value)}
                        />
                    </Flex>
                }
                {props.type === "EDIT" &&
                    <Flex gap="small" align="flex-start">
                        <Checkbox
                            id="email_permission"
                            name="email_permission"
                            checked={props.formik.values.email_permission}
                            onChange={(e: any) => props.formik.setFieldValue("email_permission", e.target.checked)}
                        />
                        <div className={styles.mailCheckbox}>
                            Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Poliçe Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
                        </div>
                    </Flex>
                }
            </Flex>
        </ModalDialog>
    );
}

export default DomesticInsuranceModal;
