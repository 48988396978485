import { IInitialValues } from "./type";

export const INITIAL_VALUES: IInitialValues = {
    company: "",
    policyType: "",
    policyNumber: "",
    uploadedFile: new File([""], ""),
    startDate: "",
    endDate: "",
    email_permission: false
}