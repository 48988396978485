import { FC, useState } from "react";
import { Flex, Button, Select } from "antd";
import { IProps } from "./type";
import styles from "./index.module.scss";

const ProfileCard: FC<IProps> = (props) => {
    const { Option } = Select;

    const [selectedId, setSelectedId] = useState<number | null>(null);

    const handleChange = (id: number) => {
        props.handleChangeRole(id);
        setSelectedId(id);
    };

    const handleClickDelete = () => {
        props.handleClickDelete();
        setSelectedId(null);
    }

    return (
        <Flex vertical gap={24} className={styles.container}>
            <Select value={selectedId} onSelect={handleChange} className={styles.select}>
                {props.roles.map(role => <Option key={role.id} value={role.id}>{role.name}</Option>)}
            </Select>
            <Flex justify="space-between">
                <span className={styles.label}>Profil Adı:</span>
                <span className={styles.value}>Admin</span>
            </Flex>
            <Flex justify="space-between">
                <span className={styles.label}>Oluşturulma Tarihi:</span>
                <span className={styles.value}>14.12.2021</span>
            </Flex>
            <Flex justify="space-between">
                <span className={styles.label}>Oluşturan Kullanıcı:</span>
                <span className={styles.value}>İhsan ÖZTÜRK</span>
            </Flex>
            <Flex justify="space-between">
                <span className={styles.label}>Bağlı Kullanıcı Sayısı:</span>
                <span className={styles.value}>24</span>
            </Flex>
            <Flex justify="space-between" gap={8}>
                <Button
                    danger
                    className={styles.button}
                    onClick={handleClickDelete}
                >
                    Sil
                </Button>
                <Button
                    className={styles.button}
                    onClick={props.handleClickEdit}
                >
                    Güncelle
                </Button>
            </Flex>
        </Flex>
    );
};

export default ProfileCard;
