import { FC } from "react";
import { IProps } from "./type";
import styles from "./index.module.scss";

const Icon: FC<IProps> = (props) => {
    return (
        <img 
            className={styles.icon} 
            src={props.source} 
            alt={props.source.toString()} 
            onClick={props.onClick ? props.onClick : () => {}}    
        />
    );
}

export default Icon;