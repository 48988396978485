import { FC, useEffect, useState } from "react";
import { Table, Typography, Flex } from "antd";
import styles from "./index.module.scss";
import { IDataItem, IProps } from "./type";
import ModalDialog from "../ModalDialog";
import { transformToData } from "./helpers";
import columns from "./columns";

const SummarySheetModal: FC<IProps> = (props) => {
    const [data, setData] = useState<IDataItem[]>([]);

    useEffect(() => {
        if (props.summaryTable.length) {
            const data = transformToData(props.summaryTable)
            setData(data);
        }
    }, [props.summaryTable]);

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            isButtonsDisplayed={false}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Table
                dataSource={data}
                columns={columns}
                pagination={false}
                className={styles.table}
            />
            <Flex vertical gap={10} className={styles.noteContainer}>
                <div className={styles.note}>
                    * Dosya takiplerinde hasar tarihi değil, ihbar tarihi baz alınmaktadır.
                </div>
                <div className={styles.note}>
                    ** Yurtiçi hasarlarında tek bir hasara bağlı demirbaş, emtia, ecs hasarları için ayrı ayrı dosya açtırılmaktadır. Aynı hasara bağlı farklı hasar dosyaları tek bir hasar dosyası olarak baz alınmaktadır.
                </div>
            </Flex>
            <Typography.Text className={styles.timestamp}>
                24/06/2024 - 15:12
            </Typography.Text>
        </ModalDialog>
    );
};

export default SummarySheetModal;
