export const policy_types: { [key: string]: string } = {
    sub_transportation: "Nakliyat Abonman",
    block_transportation: "Blok Nakliyat",
    camp_transportation: "Nakliyat Sefer"
}

export const branches: { [key: string]: string } = {
    fire: "Yangın",
    earthquake: "Deprem",
    flooding: "Sel & Su Baskını",
    internal_water: "Dahili Su",
    machine: "Makina Kırılması"
}

export const currencies: { [key: string]: string } = {
    euro: "€",
    dollar: "$",
    lira: "₺"
}

export const damage_types: { [key: string]: string } = {
    domestic: "Yurtiçi",
    abroad: "Yurtdışı",
    transportation: "Nakliyat"
}

export const statuses: { [key: string]: string } = {
    issued: "Onaylandı",
    cancelled: "İptal Edildi",
    request_created: "Talep Oluşturuldu",
    checkout: "Ödeme Aşamasında",
    report: "Rapor Aşamasında",
    updated: "Talep Güncellendi",
    documents: "Evrak Bekleniyor",
    cancel_expected: "İptal Bekleniyor"
}

export const countries = [
    { label: "Belçika", value: "BE" },
    { label: "Kanada", value: "CA" },
    { label: "Almanya", value: "DE" },
    { label: "Norveç", value: "NO" },
    { label: "İsviçre", value: "CH" },
    { label: "Türkiye", value: "TR" },
    { label: "Birleşik Krallık", value: "GB" },
    { label: "ABD", value: "US" }
];

export const cities = [
    { label: "Adana", value: "01" },
    { label: "Adıyaman", value: "02" },
    { label: "Afyonkarahisar", value: "03" },
    { label: "Ağrı", value: "04" },
    { label: "Amasya", value: "05" },
    { label: "Ankara", value: "06" },
    { label: "Antalya", value: "07" },
    { label: "Artvin", value: "08" },
    { label: "Aydın", value: "09" },
    { label: "Balıkesir", value: "10" },
    { label: "Bilecik", value: "11" },
    { label: "Bingöl", value: "12" },
    { label: "Bitlis", value: "13" },
    { label: "Bolu", value: "14" },
    { label: "Burdur", value: "15" },
    { label: "Bursa", value: "16" },
    { label: "Çanakkale", value: "17" },
    { label: "Çankırı", value: "18" },
    { label: "Çorum", value: "19" },
    { label: "Denizli", value: "20" },
    { label: "Diyarbakır", value: "21" },
    { label: "Edirne", value: "22" },
    { label: "Elazığ", value: "23" },
    { label: "Erzincan", value: "24" },
    { label: "Erzurum", value: "25" },
    { label: "Eskişehir", value: "26" },
    { label: "Gaziantep", value: "27" },
    { label: "Giresun", value: "28" },
    { label: "Gümüşhane", value: "29" },
    { label: "Hakkari", value: "30" },
    { label: "Hatay", value: "31" },
    { label: "Isparta", value: "32" },
    { label: "Mersin", value: "33" },
    { label: "İstanbul", value: "34" },
    { label: "İzmir", value: "35" },
    { label: "Kars", value: "36" },
    { label: "Kastamonu", value: "37" },
    { label: "Kayseri", value: "38" },
    { label: "Kırklareli", value: "39" },
    { label: "Kırşehir", value: "40" },
    { label: "Kocaeli", value: "41" },
    { label: "Konya", value: "42" },
    { label: "Kütahya", value: "43" },
    { label: "Malatya", value: "44" },
    { label: "Manisa", value: "45" },
    { label: "Kahramanmaraş", value: "46" },
    { label: "Mardin", value: "47" },
    { label: "Muğla", value: "48" },
    { label: "Muş", value: "49" },
    { label: "Nevşehir", value: "50" },
    { label: "Niğde", value: "51" },
    { label: "Ordu", value: "52" },
    { label: "Rize", value: "53" },
    { label: "Sakarya", value: "54" },
    { label: "Samsun", value: "55" },
    { label: "Siirt", value: "56" },
    { label: "Sinop", value: "57" },
    { label: "Sivas", value: "58" },
    { label: "Tekirdağ", value: "59" },
    { label: "Tokat", value: "60" },
    { label: "Trabzon", value: "61" },
    { label: "Tunceli", value: "62" },
    { label: "Şanlıurfa", value: "63" },
    { label: "Uşak", value: "64" },
    { label: "Van", value: "65" },
    { label: "Yozgat", value: "66" },
    { label: "Zonguldak", value: "67" },
    { label: "Aksaray", value: "68" },
    { label: "Bayburt", value: "69" },
    { label: "Karaman", value: "70" },
    { label: "Kırıkkale", value: "71" },
    { label: "Batman", value: "72" },
    { label: "Şırnak", value: "73" },
    { label: "Bartın", value: "74" },
    { label: "Ardahan", value: "75" },
    { label: "Iğdır", value: "76" },
    { label: "Yalova", value: "77" },
    { label: "Karabük", value: "78" },
    { label: "Kilis", value: "79" },
    { label: "Osmaniye", value: "80" },
    { label: "Düzce", value: "81" }
];

export const status_colors: { [key: string]: string } = {
    issued: "#87A330",
    cancelled: "#D6222A",
    request_created: "#E59500",
    checkout: "#002A4D99",
    report: "#002A4D99",
    updated: "#002A4D",
    documents: "#E5950099",
    cancel_expected: "#D6222A99"
}

export const domestic_insurance_policy_types: { [key: string]: string } = {
    policy: "Poliçe",
    addendum: "Zeyilname",
}

export const domestic_insurance_branches: { [key: string]: string } = {
    fire: "Yangın",
    liability: "Sorumluluk",
    machine: "Makina Kırılması",
    electronic_device: "Elektronik Cihaz",
    private_security: "Özel Güvenlik",
    construction_plant: "İş Makinaları",
    consigment_fire: "Konsinye Yangın"
}

export const abroad_insurance_branches: { [key: string]: string } = {
    pdbi: "PDBI",
    liability: "Sorumluluk",
    marine: "Deniz"
}

export const motor_insurance_branches: { [key: string]: string } = {
    insurance: "Kasko",
    traffic: "Trafik",
    green_card: "Yeşil Kart",
    imm: "IMM"
}
