import { IInitialValues } from "./type";

export const INITIAL_VALUES: IInitialValues = {
    key: undefined,
    company: "",
    policy_number: undefined,
    upload_files: [],
    email_permission: false,
    damage_type: "",
    branch: "",
    country: "",
    city: "",
    location_code: "",
    damage_date: "",
    pending_loss: undefined,
    currency: "",
    expert: "",
    reporter: "",
    recent_status: "",
    description: ""
};
