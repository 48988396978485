import { IColumn } from "../../../components/DataTable/type";
import { IDataSource, IOrganization } from "./type";
import { Popconfirm, Tag, Tooltip } from "antd";
import { Icon } from "../../../components";
import TrashSolidBlack from "../../../assets/icons/trash-solid-b-black.svg";
import PenToSquareSolidBlack from "../../../assets/icons/pen-to-square-solid-w-black.svg";
import FilePdfSolidBlack from "../../../assets/icons/file-pdf-solid-black.svg";
import EyeRegularWBlack from "../../../assets/icons/eye-regular-w-black.svg";
import Variant99Black from "../../../assets/icons/variant99-black.svg";
import styles from "./index.module.scss";
import { status_colors, statuses } from "../../../assets/conts";
import { downloadFile } from "../../../helpers/File";

export const getColumns = (
    handleClickEye: (id: number) => void,
    handleOpenEditModal: (id: number) => void,
    handleClickDelete: (id: number) => Promise<void>,
    handleClickCancel: (id: number) => Promise<void>,
    organizations: IOrganization[]
): IColumn<IDataSource>[] => [
        {
            title: 'Firma',
            dataIndex: 'company',
            key: 'company',
            searchable: true,
            sorter: (a, b) => a.company - b.company,
            render: (_, record) => <div>{organizations.find(item => item.id.toString() === record.company)?.name}</div>
        },
        {
            title: 'Poliçe No',
            dataIndex: 'policy_number',
            key: 'policy_number',
            sorter: (a, b) => a.policy_number - b.policy_number
        },
        {
            title: 'Sevk Tarihi',
            dataIndex: 'expedition_date',
            key: 'expedition_date',
            sorter: (a, b) => a.expedition_date - b.expedition_date
        },
        {
            title: 'Emtia Bedeli',
            dataIndex: 'insurance_value',
            key: 'insurance_value',
            sorter: (a, b) => a.insurance_value - b.insurance_value
            
        },
        {
            title: 'Poliçe Primi',
            dataIndex: 'policy_value',
            key: 'policy_value',
            sorter: (a, b) => a.policy_value - b.policy_value
        },
        {
            title: 'Sertifika Durumu',
            dataIndex: 'status',
            key: 'status',
            render: (_: any, record: IDataSource) =>
                <Tag color={status_colors[record.recent_status]}>{statuses[record.recent_status]}</Tag>
        },
        {
            title: "Açıklama",
            dataIndex: "description",
            key: "description",
            sorter: (a, b) => a.description.localeCompare(b.description),
            render: (_: any, record: IDataSource) =>
                <Tooltip title={record.description}>
                    <span>{`${record.description.slice(0, 10)}...`}</span>
                </Tooltip>
        },
        {
            title: 'Ek Dosya',
            dataIndex: 'additional_files',
            key: 'additional_files',
            render: (_: any, record: IDataSource) =>
                <div className={styles.fileRowContainer}>
                    {record.additional_files.reverse().slice(0, 2).map(item =>
                        <Popconfirm
                            title={<div className={styles.popconfirmTitle}>Ek dosyayı indirmek istiyor musunuz?</div>}
                            overlayInnerStyle={{
                                width: 150,
                                borderColor: "#E59500",
                                borderWidth: 1,
                                borderStyle: "solid"
                            }}
                            okText="İndir"
                            cancelText="İptal"
                            icon={null}
                            color="#FAFAFA"
                            okButtonProps={{ style: { backgroundColor: "#E59500", width: "45%" } }}
                            cancelButtonProps={{ style: { color: "#E59500", borderColor: "#E59500", width: "45%" } }}
                            onConfirm={() => downloadFile(item.file)}
                        >
                            <Icon source={FilePdfSolidBlack} />
                        </Popconfirm>
                    )}
                </div>
        },
        {
            title: 'İşlem',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: IDataSource) =>
                <div className={styles.actionRowContainer}>
                    <Icon source={EyeRegularWBlack} onClick={() => record?.id && handleClickEye(record.id)} />
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>İptal işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => record.id && handleClickCancel(record.id)}
                    >
                        <Icon source={Variant99Black} />
                    </Popconfirm>
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>Silme işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => record.id && handleClickDelete(record.id)}
                    >
                        <Icon source={TrashSolidBlack} />
                    </Popconfirm>
                    <Icon source={PenToSquareSolidBlack} onClick={() => record?.id && handleOpenEditModal(record.id)} />
                </div>
        }
    ];
