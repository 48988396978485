import { FC } from "react";
import {
    Flex,
    Input,
    Tree,
    Typography
} from "antd";
import { ModalDialog } from "..";
import { IProps } from "./type";
import { leftTreeData, rightTreeData } from "./const";
import styles from "./index.module.scss";

const ProfileModal: FC<IProps> = (props) => {
    const { getFieldProps, setFieldValue, values, errors, touched } = props.formik;

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex vertical>
                    <Typography className={styles.label}>Profil Bilgileri</Typography>
                    <Input
                        placeholder="Profil Adı"
                        {...getFieldProps("name")}
                    />
                    {touched.name && errors.name && (
                        <div className={styles.error}>{errors.name}</div>
                    )}
                </Flex>
                <Flex vertical>
                    <Typography className={styles.label}>Yetki Alanları</Typography>
                    <Flex justify="space-around">
                        <Tree
                            checkable
                            defaultExpandAll
                            treeData={leftTreeData}
                            className={styles.customCheckbox}
                            onCheck={(checkedKeysValue: any) => setFieldValue("leftPermissions", checkedKeysValue)}
                            checkedKeys={values.leftPermissions}
                        />
                        <Tree
                            checkable
                            defaultExpandAll
                            treeData={rightTreeData}
                            className={styles.customCheckbox}
                            onCheck={(checkedKeysValue: any) => setFieldValue("rightPermissions", checkedKeysValue)}
                            checkedKeys={values.rightPermissions}
                        />
                    </Flex>
                </Flex>
            </Flex>
        </ModalDialog>
    );
};

export default ProfileModal;
