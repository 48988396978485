import { IColumn } from "../../../components/DataTable/type";
import { IDataSource, IOrganization } from "./type";
import { Popconfirm, Tag } from "antd";
import { Icon } from "../../../components";
import TrashSolidBlack from "../../../assets/icons/trash-solid-b-black.svg";
import PenToSquareSolidBlack from "../../../assets/icons/pen-to-square-solid-w-black.svg";
import FilePdfSolidBlack from "../../../assets/icons/file-pdf-solid-black.svg";
import EyeRegularWBlack from "../../../assets/icons/eye-regular-w-black.svg";
import Variant99Black from "../../../assets/icons/variant99-black.svg";
import styles from "./index.module.scss";
import { domestic_insurance_branches, domestic_insurance_policy_types, status_colors, statuses } from "../../../assets/conts";
import { downloadFile } from "../../../helpers/File";

export const getColumns = (
    handleClickEye: (id: number) => void,
    handleOpenEditModal: (id: number) => void,
    handleClickDelete: (id: number) => Promise<void>,
    handleClickCancel: (id: number) => Promise<void>,
    organizations: IOrganization[]
): IColumn<IDataSource>[] => [
        {
            title: 'Firma',
            dataIndex: 'company',
            key: 'company',
            searchable: true,
            sorter: (a, b) => a.company - b.company,
            render: (_, record) => <div>{organizations.find(item => item.id.toString() === record.company)?.name}</div>
        },
        {
            title: 'Poliçe No',
            dataIndex: 'policy_number',
            key: 'policy_number',
            sorter: (a, b) => a.policy_number - b.policy_number
        },
        {
            title: 'Poliçe Tipi',
            dataIndex: 'type',
            key: 'type',
            sorter: (a, b) => a.type - b.type,
            render: (_, record) =>  <Tag className={styles[`${record.type}Tag`]}>{domestic_insurance_policy_types[record.type]}</Tag>,
        },
        {
            title: 'Branş',
            dataIndex: 'branch',
            key: 'branch',
            searchable: true,
            sorter: (a, b) => a.branch - b.branch,
            render: (_: any, record: IDataSource) => <div>{domestic_insurance_branches[record.branch]}</div>
        },
        {
            title: 'Başlangıç Tarihi',
            dataIndex: 'start_date',
            key: 'start_date',
            sorter: (a, b) => a.start_date - b.start_date
        },
        {
            title: 'Bitiş Tarihi',
            dataIndex: 'end_date',
            key: 'end_date',
            sorter: (a, b) => a.end_date - b.end_date
        },
        {
            title: 'Durum',
            dataIndex: 'recent_status',
            key: 'recent_status',
            render: (_: any, record: IDataSource) =>
                <Tag color={status_colors[record.recent_status]}>{statuses[record.recent_status]}</Tag>
        },
        {
            title: 'Ek Dosya',
            dataIndex: 'additional_files',
            key: 'additional_files',
            render: (_: any, record: IDataSource) => {
                if (!!record.additional_files.length)
                    return <Popconfirm
                        title={<div className={styles.popconfirmTitle}>Ek dosyayı indirmek istiyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#E59500",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="İndir"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ style: { backgroundColor: "#E59500", width: "45%" } }}
                        cancelButtonProps={{ style: { color: "#E59500", borderColor: "#E59500", width: "45%" } }}
                        onConfirm={() => downloadFile(record.additional_files[record.additional_files.length - 1].file)}
                    >
                        <Icon source={FilePdfSolidBlack} />
                    </Popconfirm>
                else return <></>
            }
        },
        {
            title: 'İşlem',
            dataIndex: 'action',
            key: 'action',
            render: (_: any, record: IDataSource) =>
                <div className={styles.actionRowContainer}>
                    <Icon source={EyeRegularWBlack} onClick={() => record?.id && handleClickEye(record.id)} />
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>İptal işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => record.id && handleClickCancel(record.id)}
                    >
                        <Icon source={Variant99Black} />
                    </Popconfirm>
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>Silme işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => record.id && handleClickDelete(record.id)}
                    >
                        <Icon source={TrashSolidBlack} />
                    </Popconfirm>
                    <Icon source={PenToSquareSolidBlack} onClick={() => record?.id && handleOpenEditModal(record.id)} />
                </div>
        }
    ];
