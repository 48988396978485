import { FC } from "react";
import { Flex, Typography } from "antd";
import { DataTable, ModalDialog } from "../";
import { IDataSource, IProps } from "./type";
import styles from "./index.module.scss";
import { getColumns } from "./columns";

const DamageNotificationModal: FC<IProps> = (props) => {
    const columns = getColumns();

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Typography className={styles.labelTitle}>Seçili Dosya</Typography>
                <DataTable<IDataSource>
                    columns={columns}
                    dataSource={props.item}
                    isPaginationDisplayed={false} 
                />
            </Flex>
            <Flex gap="middle" vertical>
                <Typography className={styles.labelTitle}>Bağlı Diğer Dosyalar</Typography>
                <DataTable<IDataSource>
                    columns={columns}
                    dataSource={props.list}
                    isPaginationDisplayed={false} 
                />
            </Flex>
        </ModalDialog>
    );
};

export default DamageNotificationModal;
