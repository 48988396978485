import { AxiosRequestConfig, AxiosResponse } from "axios";
import axiosInstance from ".";
import { notificationActions } from "../store/reducers/notificationReducer";
import { IUseHttpServiceProps } from "./type";
import { useAppDispatch } from "../store";
import { GENERAL_ERROR_MESSAGE } from "./const";
import { ErrorResponse } from "react-router-dom";

const useHttpService = () => {
    const dispatch = useAppDispatch();

    const executeService = async <T>(props: IUseHttpServiceProps): Promise<T | ErrorResponse | null> => {
        try {
            const contentType = props.contentType;
            delete props["contentType"];

            const config: AxiosRequestConfig<any> = {
                ...props,
                headers: { 
                    "Content-Type": contentType || "application/json" 
                }
            };

            const response: AxiosResponse<T | ErrorResponse> = await axiosInstance(config);
            return response.data;
        } catch (error: unknown) {
            dispatch(notificationActions.setNotification({ title: "HATA", children: GENERAL_ERROR_MESSAGE, show: true }));
            return null;
        }
    }

    return executeService;
}

export default useHttpService;
