import { FC, useRef } from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Flex,
    Input,
    Select,
    Typography,
    Row,
    Col
} from "antd";
import dayjs from "dayjs";
import { ModalDialog } from "../";
import { IProps } from "./type";
import styles from "./index.module.scss";
import { PlusOutlined } from "@ant-design/icons";
import { statuses } from "../../assets/conts";

const ExpeditionCertificateModal: FC<IProps> = (props) => {
    const { getFieldProps, setFieldValue, values, errors, touched } = props.formik;
    const uploadInput = useRef<HTMLInputElement | null>(null);

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex justify="space-between" align="flex-start">
                    <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
                    <Select
                        id="company"
                        value={props.organizations.find(item => item.id.toString() === props.formik.values.company) || undefined}
                        placeholder="Firma Seçiniz"
                        style={{ width: 200 }}
                        className={styles.input}
                        options={props.organizations.map(item => ({ value: item.id, label: item.name }))}
                        onChange={(value) => props.formik.setFieldValue("company", value)}
                    />
                </Flex>
                <Flex vertical gap="middle">
                    <Typography className={styles.labelTitle}>Emtia Bilgileri</Typography>
                    <Row align="middle" justify="space-between" gutter={16}>
                        <Col span={8}>
                            <Input
                                placeholder="Emtia Cinsi"
                                {...getFieldProps("goods_type")}
                            />
                            {touched.goods_type && errors.goods_type && (
                                <div className={styles.error}>{errors.goods_type}</div>
                            )}
                        </Col>
                        <Col span={8}>
                            <Input
                                placeholder="Emtia Miktarı"
                                {...getFieldProps("goods_quantity")}
                            />
                            {touched.goods_quantity && errors.goods_quantity && (
                                <div className={styles.error}>{errors.goods_quantity}</div>
                            )}
                        </Col>
                        <Col span={8}>
                            <Select
                                id="goods_unit"
                                value={values.goods_unit || undefined}
                                placeholder="Emtia Birimi"
                                options={[
                                    { label: "Kg", value: "kg" },
                                    { label: "Ton", value: "ton" },
                                    { label: "m³", value: "m_cube" },
                                    { label: "Palet", value: "palette" },
                                    { label: "Adet", value: "piece"}
                                ]}
                                onChange={(value) => setFieldValue("goods_unit", value)}
                                style={{
                                    width: '100%'
                                }}
                            />
                            {touched.goods_unit && errors.goods_unit && (
                                <div className={styles.error}>{errors.goods_unit}</div>
                            )}
                        </Col>
                    </Row>
                    <Row align="middle" justify="space-between" gutter={16}>
                        <Col span={6}>
                            <Input
                                placeholder="Emtia Bedeli"
                                {...getFieldProps("insurance_value")}
                            />
                            {touched.insurance_value && errors.insurance_value && (
                                <div className={styles.error}>{errors.insurance_value}</div>
                            )}
                        </Col>
                        <Col span={6}>
                            <Select
                                id="insurance_value_unit"
                                value={values.insurance_value_unit || undefined}
                                placeholder="Para Birimi"
                                style={{ width: 150 }}
                                options={[
                                    { label: "$ - Amerikan Doları", value: "dollar" },
                                    { label: "€ - Euro", value: "euro" },
                                    { label: "₺ - Türk Lirası", value: "lira" }
                                ]}
                                onChange={(value) => setFieldValue("insurance_value_unit", value)}
                            />
                            {touched.insurance_value_unit && errors.insurance_value_unit && (
                                <div className={styles.error}>{errors.insurance_value_unit}</div>
                            )}
                        </Col>
                        <Col span={6}>
                            <DatePicker
                                id="invoice_date"
                                name="invoice_date"
                                format="YYYY-MM-DD"
                                value={dayjs(values.invoice_date).isValid() ? dayjs(values.invoice_date) : null}
                                placeholder="Fatura Tarihi"
                                onChange={(date) => setFieldValue("invoice_date", dayjs(date).format("YYYY-MM-DD"))}
                            />
                            {touched.invoice_date && errors.invoice_date && (
                                <div className={styles.error}>{errors.invoice_date}</div>
                            )}
                        </Col>
                        <Col span={6}>
                            <Input
                                placeholder="Fatura No"
                                {...getFieldProps("invoice_number")}
                            />
                            {touched.invoice_number && errors.invoice_number && (
                                <div className={styles.error}>{errors.invoice_number}</div>
                            )}
                        </Col>
                    </Row>
                </Flex>
                {props.type === "EDIT" &&
                    <Flex vertical gap="middle">
                        <Typography className={styles.labelTitle}>Poliçe Bilgileri</Typography>
                        <Flex gap="middle" justify="space-between">
                            <Flex vertical style={{ width: "33%" }}>
                                <Input
                                    placeholder="Poliçe Değeri"
                                    {...getFieldProps("policy_value")}
                                />
                                {touched.policy_value && errors.policy_value && (
                                    <div className={styles.error}>{errors.policy_value}</div>
                                )}
                            </Flex>
                            <Flex vertical style={{ width: "33%" }}>
                                <Select
                                    id="policy_value_unit"
                                    value={values.policy_value_unit || undefined}
                                    placeholder="Değer Birimi"
                                    options={[
                                        { label: "$ - Amerikan Doları", value: "dollar" },
                                        { label: "€ - Euro", value: "euro" },
                                        { label: "₺ - Türk Lirası", value: "lira" }
                                    ]}
                                    onChange={(value) => setFieldValue("policy_value_unit", value)}
                                />
                                {touched.policy_value_unit && errors.policy_value_unit && (
                                    <div className={styles.error}>{errors.policy_value_unit}</div>
                                )}
                            </Flex>
                            <Flex vertical style={{ width: "33%" }}>
                                <Input
                                    placeholder="Poliçe No"
                                    {...getFieldProps("policy_number")}
                                />
                                {touched.policy_number && errors.policy_number && (
                                    <div className={styles.error}>{errors.policy_number}</div>
                                )}
                            </Flex>
                        </Flex>
                    </Flex>}
                        
                <Flex vertical gap="middle">
                    <Typography className={styles.labelTitle}>Sevkiyat Bilgileri</Typography>
                    <Flex gap="middle" justify="space-between">
                        <Flex vertical style={{ width: "33%" }}>
                            <DatePicker
                                id="expedition_date"
                                name="expedition_date"
                                format="YYYY-MM-DD"
                                value={dayjs(values.expedition_date).isValid() ? dayjs(values.expedition_date) : null}
                                placeholder="Sevk Tarihi"
                                onChange={(date) => setFieldValue("expedition_date", dayjs(date).format("YYYY-MM-DD"))}
                            />
                            {touched.expedition_date && errors.expedition_date && (
                                <div className={styles.error}>{errors.expedition_date}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "33%" }}>
                            <Input
                                placeholder="Geldiği Ülke"
                                {...getFieldProps("origin_country")}
                            />
                            {touched.origin_country && errors.origin_country && (
                                <div className={styles.error}>{errors.origin_country}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "33%" }}>
                            <Input
                                placeholder="Gittiği Ülke"
                                {...getFieldProps("destination_country")}
                            />
                            {touched.destination_country && errors.destination_country && (
                                <div className={styles.error}>{errors.destination_country}</div>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                {props.type === "EDIT" &&
                    <Flex justify="space-between">
                        <Typography>{values.upload_files?.[0]?.name || "Ek Dosya"}</Typography>
                        <Flex vertical align="flex-end">
                            <input hidden ref={uploadInput} type="file" onChange={props.handleUploadFile} />
                            <Button className={styles.button} onClick={() => uploadInput.current?.click()}>
                                <PlusOutlined className={styles.buttonIcon} />
                                <div className={styles.buttonText}>Dosya Ekle</div>
                            </Button>
                        </Flex>
                    </Flex>}
                <Flex vertical gap="middle">
                    <Typography className={styles.labelTitle}>Taşıt Bilgileri</Typography>
                    <Flex gap="middle" justify="space-between">
                        <Flex vertical style={{ width: "50%" }}>
                            <Select
                                id="vehicle_type"
                                value={values.vehicle_type || undefined}
                                placeholder="Taşıt Cinsi"
                                options={[
                                    { label: "Kamyon", value: "truck" },
                                    { label: "Uçak", value: "plane" },
                                    { label: "Gemi", value: "ship" },
                                ]}
                                onChange={(value) => setFieldValue("vehicle_type", value)}
                            />
                            {touched.vehicle_type && errors.vehicle_type && (
                                <div className={styles.error}>{errors.vehicle_type}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "50%" }}>
                            <Input
                                placeholder="Taşıt Bilgisi"
                                {...getFieldProps("vehicle_info")}
                            />
                            {touched.vehicle_info && errors.vehicle_info && (
                                <div className={styles.error}>{errors.vehicle_info}</div>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                {props.type === "EDIT" &&
                    <Flex justify="space-between">
                        <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
                        <Select
                            id="recent_status"
                            value={props.formik.values.recent_status}
                            style={{ width: 175 }}
                            className={styles[`statusDropdown-${props.formik.values.recent_status}`]}
                            options={Object.keys(statuses).map(key => ({ label: statuses[key], value: key }))}
                            onChange={(value) => props.formik.setFieldValue("recent_status", value)}
                        />
                    </Flex>
                }
                <Flex vertical gap="middle">
                    <Typography className={styles.labelTitle}>Açıklama</Typography>
                    <Flex vertical>
                        <Input.TextArea
                            placeholder="Açıklama"
                            {...getFieldProps("description")}
                        />
                        {touched.description && errors.description && (
                            <div className={styles.error}>{errors.description}</div>
                        )}
                    </Flex>
                </Flex>
                {props.type === "EDIT" &&
                    <Flex gap="small" align="flex-start">
                        <Checkbox
                            id="email_permission"
                            name="email_permission"
                            checked={props.formik.values.email_permission}
                            onChange={(e: any) => props.formik.setFieldValue("email_permission", e.target.checked)}
                        />
                        <div className={styles.mailCheckbox}>
                            Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Nakliyat Sefer Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
                        </div>
                    </Flex>
                }
            </Flex>
        </ModalDialog>
    );
};

export default ExpeditionCertificateModal;

