import { createSlice } from '@reduxjs/toolkit';
import { IInitialState, IPage, IAction, ISearch, ISort, IRow } from './type';
import { Key } from 'react';

const initialState: IInitialState = {
  page: {
    number: 0,
    size: 10,
  },
  search: {
    key: "",
    value: "",
  },
  sort: {
    key: "",
    order: ""
  },
  rows: [],
  row: 0,
  total: 0 
}

const changeTablePage = (state: IInitialState, action: IAction<IPage>) => {
  state.page = action.payload
}

const changeTableSearch = (state: IInitialState, action: IAction<ISearch>) => {
  state.search = action.payload
}

const changeTableSort = (state: IInitialState, action: IAction<ISort>) => {
  state.sort = action.payload
}

const clearTableSort = (state: IInitialState) => {
  state.sort = { key: "", order: "" };
}

const changeTableRows = (state: IInitialState, action: IAction<IRow>) => {
  const newRow = action.payload;
  const isRowExists = state.rows.some(row => row.id === newRow.id && row.propertyKey === newRow.propertyKey)
  if (isRowExists) {
    state.rows = state.rows.filter(row => row.id !== newRow.id);
  } else {
    state.rows = [ ...state.rows, newRow ];
  }
}

const changeTableRow = (state: IInitialState, action: IAction<Key>) => {
  const newRow = action.payload;
  state.row = state.row !== newRow ? newRow : 0; 
}

const changeTableTotal = (state: IInitialState, action: IAction<number>) => {
  state.total = action.payload;
}

const clearTableRows = (state: IInitialState) => {
  state.rows = [];
}

const clearTable = (state: IInitialState) => {
  state.page = { number: 0, size: 10 };
  state.search = { key: "", value: "" };
  state.sort = { key: "", order: "" };
  state.rows = [];
  state.row = 0;
  state.total = 0;
};

export const tableSlice = createSlice({
  name: 'table',
  initialState,
  reducers: {
    changeTablePage,
    changeTableSearch,
    changeTableSort,
    clearTableSort,
    changeTableRows,
    changeTableRow,
    changeTableTotal,
    clearTableRows,
    clearTable
  }
});

export const tableActions = tableSlice.actions;
const tableReducer = tableSlice.reducer;
export default tableReducer;