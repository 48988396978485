import { IInitialValues } from "./type";

export const INITIAL_VALUES: IInitialValues = {
    id: undefined,
    upload_files: [],
    email_permission: false,
    recent_status: "",
    company: "",
    policy_number: undefined,
    start_date: "",
    end_date: "",
    description: "",
    country: "",
    branch: ""
}