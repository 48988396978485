import { FC } from 'react';
import { 
  Navigate, 
  RouterProvider, 
  createBrowserRouter 
} from 'react-router-dom';
import { PrivateRoute } from './components';
import { 
  Login, 
  Policy, 
  ExpeditionCertificate, 
  DamageManagement, 
  ProfileSettings, 
  UserSettings, 
  DomesticInsurance,
  AbroadInsurance,
  MotorInsurance
} from './pages';

const App: FC = () => {
  const router = createBrowserRouter([
    {
      path: "login",
      element: <Login />,
    },
    {
      path: "/",
      element: <PrivateRoute />,
      children: [
        {
          path: "freight-insurance",
          children: [
            {
              path: "policy",
              element: <Policy />
            },
            {
              path: "expedition-certificate",
              element: <ExpeditionCertificate />
            }
          ]
        },
        {
          path: "damage-management",
          element: <DamageManagement />
        },
        {
          path: "main-insurances",
          children: [
            {
              path: "domestic-insurance",
              element: <DomesticInsurance />
            },
            {
              path: "abroad-insurance",
              element: <AbroadInsurance />
            }
          ]
        },
        {
          path: "user-profiles-and-authorisation",
          children: [
            {
              path: "profile-settings",
              element: <ProfileSettings />
            },
            {
              path: "user-settings",
              element: <UserSettings />
            }
          ]
        },
        {
          path: "motor-insurance",
          element: <MotorInsurance />
        },
      ],
    },
    {
      path: "*",
      element: <Navigate to="/" replace />,
    }
  ]);

  return (
    <div>
      <RouterProvider router={router} />
    </div>
  );
}

export default App;
