import { ColumnsType } from "antd/es/table";

const columns: ColumnsType<any> = [
    {
        title: "SON DURUM",
        dataIndex: "status",
        key: "status"
    },
    {
        title: "Yurtiçi",
        dataIndex: "domestic",
        key: "domestic"
    },
    {
        title: "Nakliyat",
        dataIndex: "transportation",
        key: "transportation"
    },
    {
        title: "Yurtdışı",
        dataIndex: "abroad",
        key: "abroad"
    },
    {
        title: "Toplam",
        dataIndex: "total",
        key: "total"
    },
];

export default columns;