import { FC, useState } from "react";
import { BellFilled, QuestionCircleFilled, SettingFilled, } from "@ant-design/icons";
import Logo from "../../assets/images/logo.svg";
import styles from "./index.module.scss";
import { Link, useNavigate } from "react-router-dom";
import { Divider, Dropdown, Flex, MenuProps } from "antd";
import ResetPasswordModal from "../ResetPasswordModal";
import { logout } from "../../helpers/Auth";

const Header: FC = () => {
    const navigate = useNavigate();
    const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
    const handleClickLogo = () => navigate("/");
    const handleClickResetPassword = () => setIsModalOpen(true);
    const handleCloseResetPassword = () => setIsModalOpen(false);
    const handleClickLogout = () => logout();

    const items: MenuProps['items'] = [
        {
            key: '1',
            label: (
                <Link to="#">
                    Profil
                </Link>
            ),
        },
        {
            key: '2',
            label: (
                <Link onClick={handleClickResetPassword} to="#">
                    Şifre Değiştirme
                </Link>
            ),
        },
        {
            key: '3',
            label: (
                <Link onClick={handleClickLogout} to="#">
                    Çıkış Yap
                </Link>
            ),
        },
    ];

    const first_name = localStorage.getItem("first_name");
    const last_name = localStorage.getItem("last_name");

    return (
        <header className={styles.headerContainer}>
            <div className={styles.headerLogoContainer} onClick={handleClickLogo}>
                <img alt="logo" src={Logo} />
            </div>
            <Flex align="center" gap={14} className={styles.rightSide}>
                <QuestionCircleFilled className={styles.icon} />
                <SettingFilled className={styles.icon} />
                <BellFilled className={styles.icon} />
                <Divider type="vertical" className={styles.verticalLine} />
                <Dropdown 
                    menu={{ items }} 
                    trigger={['click']} 
                    placement="bottomRight"
                    rootClassName={styles.dropdown}
                    >
                    <Flex align="center" gap={14}>
                        <div className={styles.content}>{first_name} {last_name}</div>
                        <Flex justify="center" align="center" className={styles.firstLetterContainer}>
                            <div className={styles.content}>{first_name?.[0]}</div>
                        </Flex>
                    </Flex>
                </Dropdown>
            </Flex>
            <ResetPasswordModal
                title="Şifre Değiştirme"
                subTitle="Şifre Değiştirme"
                open={isModalOpen}
                handleSubmit={handleCloseResetPassword}
                handleCancel={handleCloseResetPassword}
            />
        </header>
    );
}

export default Header;