import * as Yup from "yup";

const validationSchema = Yup.object().shape({
    company: Yup
        .string()
        .required("Firma zorunludur."),
    policyType: Yup
        .string()
        .required("Poliçe tipi zorunludur."),
    policyNumber: Yup
        .string()
        .required("Poliçe numarası zorunludur.")
        .min(3, "En az 3 hane olmalıdır"),
    uploadedFile: Yup
        .string()
        .required("Dosya yüklenmesi zorunludur.")
});

export default validationSchema;