import { DataNode } from "antd/es/tree";

export const leftTreeData: DataNode[] = [
  {
    title: "Nakliyat Sigortaları",
    key: "T-0",
    children: [
      {
        title: "Poliçe Görüntüleme",
        key: "T-0-0",
        children: [
          {
            title: "Listeleme",
            key: 48,
          },
          {
            title: "Ekleme",
            key: 45,
          },
          {
            title: "Güncelleme",
            key: 46,
          },
          {
            title: "Silme",
            key: 47,
          },
        ],
      },
      {
        title: "Sefer Sertifikası Görüntüleme",
        key: "T-0-1",
        children: [
          {
            title: "Listeleme",
            key: 52,
          },
          {
            title: "Ekleme",
            key: 49,
          },
          {
            title: "Güncelleme",
            key: 50,
          },
          {
            title: "Silme",
            key: 51,
          },
        ],
      },
    ],
  },
  {
    title: "Hasar Yönetimi",
    key: "T-1",
    children: [
      {
        title: "Listeleme",
        key: 68,
      },
      {
        title: "Ekleme",
        key: 65,
      },
      {
        title: "Güncelleme",
        key: 66,
      },
      {
        title: "Silme",
        key: 67,
      },
    ],
  }
];

export const rightTreeData: DataNode[] = [
  {
    title: "Ana Sigorta Poliçeleri",
    key: "T-2",
    children: [
      {
        title: "Yurtiçi Sigortalar",
        key: "T-2-0",
        children: [
          {
            title: "Listeleme",
            key: 76,
          },
          {
            title: "Ekleme",
            key: 73,
          },
          {
            title: "Güncelleme",
            key: 74,
          },
          {
            title: "Silme",
            key: 75,
          },
        ],
      },
      {
        title: "Yurtdışı Sigortalar",
        key: "T-2-1",
        children: [
          {
            title: "Listeleme",
            key: 72,
          },
          {
            title: "Ekleme",
            key: 69,
          },
          {
            title: "Güncelleme",
            key: 70,
          },
          {
            title: "Silme",
            key: 71,
          },
        ],
      }
    ],
  },
];