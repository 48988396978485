import { createSlice } from "@reduxjs/toolkit";
import { INotificationInitialStateType, IAction } from "./type";

const initialState: INotificationInitialStateType = {
    title: "",
    children: "",
    show: false
}

const setNotification = (state: INotificationInitialStateType, action: IAction<INotificationInitialStateType>) => {
    state = action.payload;
}

const notificationSlice = createSlice({
    name: "notification",
    initialState,
    reducers: { setNotification }
});

export const notificationActions = notificationSlice.actions;
const notificationReducer = notificationSlice.reducer;
export default notificationReducer;