import { IColumn } from "../../../components/DataTable/type";
import { IDataSource } from "./type";
import styles from "./index.module.scss";
import { Icon } from "../../../components";
import TrashSolidBlack from "../../../assets/icons/trash-solid-b-black.svg";
import PenToSquareSolidBlack from "../../../assets/icons/pen-to-square-solid-w-black.svg";
import { Popconfirm, Tag } from "antd";
import dayjs from "dayjs";

export const getColumns = (
    handleClickDelete: (id: number) => Promise<void>,
    handleOpenEditModal: (id: number) => Promise<void>
): IColumn<IDataSource>[] => [
        {
            title: "Firma",
            dataIndex: "organization",
            key: "organization",
            searchable: true,
            sorter: (a, b) => a.organization - b.organization
        },
        {
            title: "İsim",
            dataIndex: "first_name",
            key: "first_name",
            sorter: (a, b) => a.first_name - b.first_name
        },
        {
            title: "Soyisim",
            dataIndex: "last_name",
            key: "last_name",
            sorter: (a, b) => a.last_name - b.last_name
        },
        {
            title: "Rol",
            dataIndex: "role",
            key: "role",
            searchable: true,
            sorter: (a, b) => a.role - b.role
        },
        {
            title: "Pozisyon",
            dataIndex: "position",
            key: "position",
            searchable: true,
            sorter: (a, b) => a.position - b.position
        },
        {
            title: "Departman",
            dataIndex: "department",
            key: "department",
            sorter: (a, b) => a.department - b.department
        },
        {
            title: "Email",
            dataIndex: "email",
            key: "email",
            sorter: (a, b) => a.email - b.email
        },
        {
            title: "Telefon",
            dataIndex: "phone_number",
            key: "phone_number",
            sorter: (a, b) => a.phone_number - b.phone_number
        },
        {
            title: "Kayıt Tarihi",
            dataIndex: "created_at",
            key: "created_at",
            sorter: (a, b) => a.created_at - b.created_at,
            render: (_, record) => <div>{record.created_at ? dayjs(record.created_at).format("YYYY-MM-DD") : null}</div>,
        },
        {
            title: "Oluşturan Kullanıcı",
            dataIndex: "created_by",
            key: "created_by",
            sorter: (a, b) => a.created_by - b.created_by
        },
        {
            title: "Durum",
            dataIndex: "is_active",
            key: "is_active",
            sorter: (a, b) => a.is_active - b.is_active,
            render: (_: any, record: IDataSource) =>
                <Tag color={record.is_active ? "#87A330" : "#D6222A"}>{record.is_active ? "Aktif" : "Silindi"}</Tag>
        },
        {
            title: "İşlem",
            dataIndex: "action",
            key: "action",
            render: (_: any, record: IDataSource) => (
                <div className={styles.actionRowContainer}>
                    <Popconfirm
                        title={<div className={styles.popconfirmTitle}>Silme işlemini onaylıyor musunuz?</div>}
                        overlayInnerStyle={{
                            width: 150,
                            borderColor: "#D6222A",
                            borderWidth: 1,
                            borderStyle: "solid"
                        }}
                        okText="Onayla"
                        cancelText="İptal"
                        icon={null}
                        color="#FAFAFA"
                        okButtonProps={{ danger: true, style: { width: "45%" } }}
                        cancelButtonProps={{ danger: true, style: { width: "45%" } }}
                        onConfirm={() => record.id && handleClickDelete(record.id)}
                    >
                        <Icon source={TrashSolidBlack} />
                    </Popconfirm>
                    <Icon
                        source={PenToSquareSolidBlack}
                        onClick={() => record.id && handleOpenEditModal(record.id)}
                    />
                </div>
            ),
        }
    ];
