import { FC, PropsWithChildren, useEffect } from "react";
import { Header, Sidemenu } from "../";
import useHttpService from "../../services/hook";
import { RequestMethods } from "../../services/enum";
import { IResponse } from "./type";

const Layout: FC<PropsWithChildren> = ({ children }) => {
    const executeService = useHttpService();

    const handleFetchUserInfo = async () => {
        const response = await executeService<IResponse>({ method: RequestMethods.GET, url: "auth/user/me/" });
        if (response && "id" in response) {
            localStorage.setItem("first_name", response.first_name);
            localStorage.setItem("last_name", response.last_name);
            localStorage.setItem("email", response.email);
        }
    }

    useEffect(() => {
        handleFetchUserInfo();
    }, []);

    return (
        <div>
            <Header />
            <div style={{ display: "flex", width: "100%"}}>
                <Sidemenu />
                <div style={{ width: "100%" }}>{children}</div>
            </div>
        </div>
    );
}

export default Layout;