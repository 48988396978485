import { FC } from "react";
import { useFormik } from "formik";
import { Flex, Input } from "antd";
import { ModalDialog } from "..";
import { IProps, IInitialValues } from "./type";
import styles from "./index.module.scss";
import { INITIAL_VALUES } from "./const";
import validationSchema from "./validations";

const ResetPasswordModal: FC<IProps> = (props) => {

    const onSubmit = (values: IInitialValues) => {

    }

    const formik = useFormik<IInitialValues>({ initialValues: INITIAL_VALUES, validationSchema, onSubmit });

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap={30} vertical className={styles.container}>
                <Input
                    placeholder="Eski Şifre"
                    {...formik.getFieldProps("oldPassword")}
                />
                {formik.touched.oldPassword && formik.errors.oldPassword && (
                    <div className={styles.error}>{formik.errors.oldPassword}</div>
                )}
                <Input
                    placeholder="Yeni Şifre"
                    {...formik.getFieldProps("newPassword")}
                />
                {formik.touched.newPassword && formik.errors.newPassword && (
                    <div className={styles.error}>{formik.errors.newPassword}</div>
                )}
                <Input
                    placeholder="Yeni Şifre Tekrar"
                    {...formik.getFieldProps("newPasswordAgain")}
                />
                {formik.touched.newPasswordAgain && formik.errors.newPasswordAgain && (
                    <div className={styles.error}>{formik.errors.newPasswordAgain}</div>
                )}
            </Flex>
        </ModalDialog>
    );
}

export default ResetPasswordModal;