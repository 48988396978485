import { IPage, ISearch, ISort } from "../../store/reducers/tableReducer/type";
import { IDataSource, IInitialValues } from "./type";

export const prepareFetchParameters = (dates?: string[], search?: ISearch, sort?: ISort, page?: IPage): string => {
    const params = new URLSearchParams();

    page?.size && params.append("limit", page?.size.toString());
    typeof page?.number === "number" && params.append("offset", (page.size * page.number).toString());
    dates?.[0] && params.append('start_date', dates[0]);
    dates?.[1] && params.append('end_date', dates[1]);
    search?.key && search?.value && params.append(search.key, search.value);
    sort?.key && sort.order && params.append("ordering", sort.order === "ascend" ? sort.key : `-${sort.key}`)

    return params.size ? `?${params.toString()}` : '';
};

export const prepareFormData = (values: IInitialValues): FormData => {
    const formData = new FormData();
    Object.keys(values).forEach(key => {
        if (key === "upload_files")
            values[key][0]?.size > 0 && formData.append(key, values[key][0]);
        else if (key === "email_permission")
            formData.append(key, (!!values[key]).toString());
        else
            formData.append(key, values[key] || "");
    });
    return formData;
};

export const prepareModalData = (item?: IDataSource): IInitialValues | undefined => {
    if (item) {
        return {
            id: item.id,
            upload_files: [new File([], item.additional_files[0]?.name || "")],
            branch: [item.branch],
            email_permission: item.email_permission,
            company: item.company,
            policy_number: item.policy_number,
            start_date: item.start_date,
            end_date: item.end_date,
            description: item.description,
            license_plate: item.license_plate,
            recent_status: item.recent_status
        } as IInitialValues
    }
};

export const cleanupAfterSubmit = (
    setIsModalOpen: (open: boolean) => void,
    setSelectedItem: (item?: IDataSource) => void,
    formikReset: () => void,
    handleFetchData: () => void
) => {
    setIsModalOpen(false);
    setSelectedItem(undefined);
    formikReset();
    handleFetchData();
};