import { FC, useEffect, useState } from "react";
import { useFormik } from "formik";
import { Button, Card, Divider, Flex, Tree } from "antd";
import { PlusOutlined } from "@ant-design/icons";
import { ProfileModal, DeleteModal } from "../../../components";
import { IInitialValues, IResponse, IRole, IRolePermissions } from "./type";
import { ProfileCard } from "./components";
import { INITIAL_VALUES, leftTreeData, rightTreeData } from "./const";
import styles from "./index.module.scss";
import useHttpService from "../../../services/hook";
import { RequestMethods } from "../../../services/enum";
import { extractKeys } from "./helpers";

const ProfileSettings: FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const [selectedItem, setSelectedItem] = useState<IRolePermissions | undefined>(undefined);
  const [roles, setRoles] = useState<IRole[]>([]);
  const [isEditModalOpen, setIsEditModalOpen] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);

  const executeService = useHttpService();

  const handleOpenModal = () => {
    setSelectedItem(undefined);
    setIsModalOpen(true);
  }
  const handleCloseModal = () => setIsModalOpen(false);
  const handleClickEdit = () => setIsEditModalOpen(true);
  const handleCloseEdit = () => setIsEditModalOpen(false);
  const handleClickDelete = () => setIsDeleteModalOpen(true);

  const onLeftCheck = (checkedKeysValue: any) => formik.setFieldValue("leftPermissions", checkedKeysValue);
  const onRightCheck = (checkedKeysValue: any) => formik.setFieldValue("rightPermissions", checkedKeysValue);

  const handleFetchRoles = async () => {
    try {
      const response = await executeService<IResponse>({ method: RequestMethods.GET, url: "auth/role/" });
      if (response && "results" in response) {
        setRoles(response.results);
      }
    } catch (e) {
      console.log(e);
    }
  }

  const handleChangeRole = async (id: number) => {
    const selectedItem = await executeService<IRolePermissions>({ method: RequestMethods.GET, url: `auth/role/${id}/` });
    selectedItem && "id" in selectedItem && setSelectedItem(selectedItem);
  }

  const onSubmit = async (values: IInitialValues) => {
    try {
      const { name, leftPermissions, rightPermissions } = values;
      const filteredLeftPermissions = leftPermissions.filter(item => !item.toString().startsWith("T-")).map(item => Number(item));
      const filteredRightPermissions = rightPermissions.filter(item => !item.toString().startsWith("T-")).map(item => Number(item));
      const data = {
        name,
        permissions: [...filteredLeftPermissions, ...filteredRightPermissions]
      }
      if (selectedItem) {
        await executeService<unknown>({ method: RequestMethods.POST, url: `auth/role/${selectedItem.id}/assign-permissions/`, data });
      } else {
        await executeService<unknown>({ method: RequestMethods.POST, url: "auth/role/", data });
      }
    } catch (e) {
      console.log(e);
    } finally {
      setIsModalOpen(false);
      setIsEditModalOpen(false);
      handleFetchRoles();
    }
  }

  const onCancel = () => setIsDeleteModalOpen(false);
  const onConfirm = async () => {
    try {
      await executeService<unknown>({ method: RequestMethods.DELETE, url: `auth/role/${selectedItem?.id}/` });
    } catch (e) {
      console.log(e);
    } finally {
      handleFetchRoles();
      setIsDeleteModalOpen(false);
      setSelectedItem(undefined);
    }
  }

  const formik = useFormik<IInitialValues>({ initialValues: INITIAL_VALUES, onSubmit });

  useEffect(() => {
    handleFetchRoles();
  }, []);

  useEffect(() => {
    if (selectedItem) {
      const values: IInitialValues = {
        name: selectedItem.name,
        leftPermissions: selectedItem.permissions.filter(item => extractKeys(leftTreeData).includes(item.id)).map(item => item.id),
        rightPermissions: selectedItem.permissions.filter(item => extractKeys(rightTreeData).includes(item.id)).map(item => item.id)
      }
      formik.setValues(values);
    } else {
      formik.setValues(INITIAL_VALUES);
    }
  }, [selectedItem]);

  return (
    <Flex vertical gap={10} className={styles.container}>
      <Flex justify="center" align="center" gap={10} className={styles.subHeader}>
        <div className={styles.leftLine} />
        <div className={styles.pageName}>Kullanıcı Profilleri & Yetkilendirme</div>
        <div className={styles.rightLine} />
      </Flex>
      <Flex justify="flex-end">
        <Button size="large" className={styles.addButton} onClick={handleOpenModal}>
          <PlusOutlined className={styles.buttonIcon} />
          <div className={styles.buttonText}>Yeni Profil Oluştur</div>
        </Button>
      </Flex>
      <Card>
        <Flex className={styles.bottomContainer} justify="space-between">
          <ProfileCard
            roles={roles}
            handleClickEdit={handleClickEdit}
            handleClickDelete={handleClickDelete}
            handleChangeRole={handleChangeRole}
          />
          <Divider type="vertical" className={styles.divider} />
          <Flex justify="space-between" className={styles.treeContainer}>
            <Tree
              checkable
              defaultExpandAll
              disabled={true}
              treeData={leftTreeData}
              className={styles.customCheckbox}
              onCheck={onLeftCheck}
              checkedKeys={formik.values.leftPermissions}
            />
            <Tree
              checkable
              defaultExpandAll
              disabled={true}
              treeData={rightTreeData}
              className={styles.customCheckbox}
              onCheck={onRightCheck}
              checkedKeys={formik.values.rightPermissions}
            />
          </Flex>
        </Flex>
      </Card>
      <ProfileModal
        open={isModalOpen}
        title="Yeni Profil Oluşturma"
        subTitle="Yeni Kullanıcı Profili Oluşturma"
        formik={formik}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseModal}
      />
      <ProfileModal
        open={isEditModalOpen}
        title="Profil Güncelleme"
        subTitle="Kullanıcı Profil Güncelleme"
        formik={formik}
        handleSubmit={formik.handleSubmit}
        handleCancel={handleCloseEdit}
      />
      <DeleteModal open={isDeleteModalOpen} handleOk={onConfirm} handleCancel={onCancel} />
    </Flex>
  );
};

export default ProfileSettings;
