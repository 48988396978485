import * as Yup from 'yup';

const validationSchema = Yup.object().shape({
  damage_type: Yup.string().required('Hasar Tipi zorunludur'),
  
  country: Yup.string().test(
    'country',
    'Ülke zorunludur',
    function (value) {
      const { damage_type } = this.parent;
      if (damage_type === 'transportation') {
        return true;
      }
      return !!value;
    }
  ),

  city: Yup.string().test(
    'city',
    'Şehir zorunludur',
    function (value) {
      const { damage_type } = this.parent;
      if (damage_type === 'abroad') {
        return true;
      }
      if (damage_type === 'transportation') {
        return true;
      }
      return !!value;
    }
  ),

  location_code: Yup.string().test(
    'location_code',
    'Lokasyon Kodu zorunludur',
    function (value) {
      const { damage_type } = this.parent;
      if (damage_type === 'transportation') {
        return true;
      }
      return !!value;
    }
  ),
  
  branch: Yup.string().required('Hasar Branşı zorunludur'),
  damage_date: Yup.string().required('Hasar Tarihi zorunludur'),
  pending_loss: Yup.string().required('Muallak Hasar Bedeli zorunludur'),
  currency: Yup.string().required('Değer Birimi zorunludur'),
  description: Yup.string().required('Açıklama zorunludur')
});

export default validationSchema;
