import { Popconfirm, Tag, Tooltip } from "antd";
import { IColumn } from "../../components/DataTable/type";
import { Icon } from "../../components";
import TrashSolidBlack from "../../assets/icons/trash-solid-b-black.svg";
import PenToSquareSolidBlack from "../../assets/icons/pen-to-square-solid-w-black.svg";
import EyeRegularWBlack from "../../assets/icons/eye-regular-w-black.svg";
import Variant99Black from "../../assets/icons/variant99-black.svg";
import Subtract from "../../assets/icons/Subtract.svg";
import { IDataSource, IOrganization } from "./type";
import styles from "./index.module.scss";
import { branches, cities, countries, currencies, damage_types, status_colors, statuses } from "../../assets/conts";
import dayjs from "dayjs";

export const getColumns = (
  handleClickEye: (id: number) => void,
  handleClickDelete: (id: number) => Promise<void>,
  handleClickCancel: (id: number) => Promise<void>,
  handleOpenEditModal: (id: number) => Promise<void>,
  handleClickRemoveParent: (parent: number, id: number) => Promise<void>,
  organizations: IOrganization[]
): IColumn<IDataSource>[] => [
    {
      title: "Firma",
      dataIndex: "company",
      key: "company",
      searchable: true,
      sorter: (a, b) => a.company.localeCompare(b.company),
      render: (_, record) => <div>{organizations.find(item => item.id.toString() === record.company)?.name}</div>
    },
    {
      title: "Dosya No",
      dataIndex: "policy_number",
      key: "policy_number",
      sorter: (a, b) => a.policy_number - b.policy_number
    },
    {
      title: "Bağlı Dosya No",
      dataIndex: "parent",
      key: "parent",
      sorter: (a, b) => a.parent - b.parent
    },
    {
      title: "Hasar Tipi",
      dataIndex: "damage_type",
      key: "damage_type",
      searchable: true,
      sorter: (a, b) => a.damage_type.localeCompare(b.damage_type),
      render: (_: any, record: IDataSource) =>
        <Tag className={styles[`${record.damage_type}Tag`]}>{damage_types[record.damage_type]}</Tag>
    },
    {
      title: "Branş",
      dataIndex: "branch",
      key: "branch",
      searchable: true,
      sorter: (a, b) => a.branch.localeCompare(b.branch),
      render: (_: any, record: IDataSource) => <div>{branches[record.branch]}</div>,
    },
    {
      title: "Hasar Yeri",
      dataIndex: "country",
      key: "country",
      sorter: (a, b) => a.country.localeCompare(b.country),
      render: (_, record: IDataSource) => <div>{countries.find(country => country.value === record.country?.toUpperCase())?.label} {cities.find(city => city.value === record.city) && `/ ${cities.find(city => city.value === record.city)?.label}`}</div>,
    },
    {
      title: "İhbar Tarihi",
      dataIndex: "notice_date",
      key: "notice_date",
      sorter: (a, b) => new Date(a.notice_date).getTime() - new Date(b.notice_date).getTime(),
      render: (_, record) => <div>{record.notice_date ? dayjs(record.notice_date).format("YYYY-MM-DD") : ""}</div>
    },
    {
      title: "Hasar Tarihi",
      dataIndex: "damage_date",
      key: "damage_date",
      sorter: (a, b) => new Date(a.damage_date).getTime() - new Date(b.damage_date).getTime(),
      render: (_, record) => <div>{record.damage_date ? dayjs(record.damage_date).format("YYYY-MM-DD") : ""}</div>
    },
    {
      title: "Muallak Hasar Bedeli",
      dataIndex: "pending_loss",
      key: "pending_loss",
      sorter: (a, b) => a.pending_loss - b.pending_loss,
      render: (_: any, record: IDataSource) =>
        <div>{record.pending_loss} {currencies[record.currency]}</div>
    },
    {
      title: "Durum",
      dataIndex: "recent_status",
      key: "recent_status",
      searchable: true,
      sorter: (a, b) => a.recent_status.localeCompare(b.recent_status),
      render: (_: any, record: IDataSource) =>
        <Tag color={status_colors[record.recent_status]}>{statuses[record.recent_status]}</Tag>
    },
    {
      title: "Açıklama",
      dataIndex: "description",
      key: "description",
      sorter: (a, b) => a.description.localeCompare(b.description),
      render: (_: any, record: IDataSource) =>
        <Tooltip title={record.description}>
          <span>{`${record.description.slice(0, 10)}...`}</span>
        </Tooltip>
    },
    {
      title: "Eksper / Raportör",
      dataIndex: "expert",
      key: "expert",
      sorter: (a, b) => a.expert.localeCompare(b.expert)
    },
    {
      title: 'İşlem',
      dataIndex: 'action',
      key: 'action',
      render: (_: any, record: IDataSource) =>
        <div className={styles.actionRowContainer}>
          {record.parent && <Icon source={Subtract} onClick={() => handleClickRemoveParent(record.parent, record.id)} />}
          <Icon source={EyeRegularWBlack} onClick={() => record?.id && handleClickEye(record.id)} />
          <Popconfirm
            title={<div className={styles.popconfirmTitle}>İptal işlemini onaylıyor musunuz?</div>}
            overlayInnerStyle={{
              width: 150,
              borderColor: "#D6222A",
              borderWidth: 1,
              borderStyle: "solid"
            }}
            okText="Onayla"
            cancelText="İptal"
            icon={null}
            color="#FAFAFA"
            okButtonProps={{ danger: true, style: { width: "45%" } }}
            cancelButtonProps={{ danger: true, style: { width: "45%" } }}
            onConfirm={() => handleClickCancel(record.id)}
          >
            <Icon source={Variant99Black} />
          </Popconfirm>
          <Popconfirm
            title={<div className={styles.popconfirmTitle}>Silme işlemini onaylıyor musunuz?</div>}
            overlayInnerStyle={{
              width: 150,
              borderColor: "#D6222A",
              borderWidth: 1,
              borderStyle: "solid"
            }}
            okText="Onayla"
            cancelText="İptal"
            icon={null}
            color="#FAFAFA"
            okButtonProps={{ danger: true, style: { width: "45%" } }}
            cancelButtonProps={{ danger: true, style: { width: "45%" } }}
            onConfirm={() => handleClickDelete(record.id)}
          >
            <Icon source={TrashSolidBlack} />
          </Popconfirm>

          <Icon source={PenToSquareSolidBlack} onClick={() => handleOpenEditModal(record.id)} />
        </div>
    }
  ];