import { IInitialValues } from "./type";


export const INITIAL_VALUES: IInitialValues = {
    id: undefined,
    email: "",
    first_name: "",
    last_name: "",
    position: "",
    department: "",
    phone_number: "",
    address: "",
    role: "",
    organization: "",
    created_at: "",
    created_by: "",
    is_verified: false,
    is_active: false
};
