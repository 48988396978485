import { FC, useEffect, useRef } from "react";
import {
    Button,
    Checkbox,
    DatePicker,
    Flex,
    Input,
    Select,
    Typography
} from "antd";
import { PlusOutlined } from "@ant-design/icons";
import dayjs from "dayjs";
import { ModalDialog } from "../";
import { IProps } from "./type";
import styles from "./index.module.scss";
import { cities, countries, statuses } from "../../assets/conts";

const DamageNotificationModal: FC<IProps> = (props) => {
    const { getFieldProps, setFieldValue, values, errors, touched } = props.formik;
    const uploadInput = useRef<HTMLInputElement | null>(null);

    useEffect(() => {
        if (values.damage_type === "domestic") setFieldValue("country", "TR");
        else if (values.damage_type === "abroad") setFieldValue("city", "");
        else if (values.damage_type === "transportation") {
            setFieldValue("city", "");
            setFieldValue("country", "");
            setFieldValue("location_code", "");
        }
    }, [values.damage_type, setFieldValue]);

    return (
        <ModalDialog
            title={props.title}
            subTitle={props.subTitle}
            open={props.open}
            handleOk={props.handleSubmit}
            handleCancel={props.handleCancel}
        >
            <Flex gap="middle" vertical>
                <Flex gap="middle" align="flex-start">
                    <Typography className={styles.labelTitle}>Firma Bilgileri</Typography>
                    <Select
                        id="company"
                        value={props.organizations.find(item => item.id.toString() === props.formik.values.company)?.name || undefined}
                        placeholder="Firma Seçiniz"
                        style={{ width: 200 }}
                        className={styles.input}
                        options={props.organizations.map(item => ({ label: item.name, value: item.id }))}
                        onChange={(value) => props.formik.setFieldValue("company", value)}
                    />
                    {props.formik.touched.company && props.formik.errors.company && (
                        <div className={styles.error}>{props.formik.errors.company}</div>
                    )}
                </Flex>
                <Flex vertical gap={12}>
                    <Typography className={styles.labelTitle}>Hasar Bilgileri</Typography>
                    <Flex gap="middle" justify="space-between">
                        <Flex vertical style={{ width: "25%" }}>
                            <Select
                                placeholder="Hasar Tipi"
                                options={[
                                    { label: "Yurtiçi", value: "domestic" },
                                    { label: "Yurtdışı", value: "abroad" },
                                    { label: "Nakliyat", value: "transportation" }
                                ]}
                                value={values.damage_type || undefined}
                                onChange={(value) => setFieldValue("damage_type", value)}
                            />
                            {touched.damage_type && errors.damage_type && (
                                <div className={styles.error}>{errors.damage_type}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <Select
                                placeholder="Ülke"
                                disabled={values.damage_type === "transportation" || values.damage_type === "domestic"}
                                options={countries.map(option => ({ label: option.label, value: option.value?.toUpperCase() }))}
                                value={values.country || undefined}
                                onChange={(value) => setFieldValue("country", value)}
                            />
                            {touched.country && errors.country && (
                                <div className={styles.error}>{errors.country}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <Select
                                disabled={values.damage_type === "transportation" || values.damage_type === "abroad"}
                                placeholder="Şehir"
                                options={cities}
                                value={values.city || undefined}
                                onChange={(value) => setFieldValue("city", value)}
                            />
                            {touched.city && errors.city && (
                                <div className={styles.error}>{errors.city}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <Input
                                disabled={values.damage_type === "transportation"}
                                placeholder="Lokasyon Kodu"
                                {...getFieldProps("location_code")}
                            />
                            {touched.location_code && errors.location_code && (
                                <div className={styles.error}>{errors.location_code}</div>
                            )}
                        </Flex>
                    </Flex>
                    <Flex gap="middle" justify="space-between">
                        <Flex vertical style={{ width: "25%" }}>
                            <Select
                                placeholder="Hasar Branşı"
                                options={[
                                    { value: "fire", label: "Yangın" },
                                    { value: "earthquake", label: "Deprem" },
                                    { value: "flooding", label: "Sel & Su Baskını" },
                                    { value: "internal_water", label: "Dahili Su" },
                                    { value: "machine", label: "Makina Kırılması" }
                                ]
                                }
                                value={values.branch || undefined}
                                onChange={(value) => setFieldValue("branch", value)}
                            />
                            {touched.branch && errors.branch && (
                                <div className={styles.error}>{errors.branch}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <DatePicker
                                placeholder="Hasar Tarihi"
                                format="YYYY-MM-DD"
                                value={dayjs(values.damage_date).isValid() ? dayjs(values.damage_date) : null}
                                onChange={(date) => setFieldValue("damage_date", dayjs(date).format("YYYY-MM-DDTHH:mm:ssZ"))}
                            />
                            {touched.damage_date && errors.damage_date && (
                                <div className={styles.error}>{errors.damage_date}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <Input
                                id="pending_loss"
                                name="pending_loss"
                                value={values.pending_loss}
                                placeholder="Muallak Hasar Bedeli"
                                onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                                    setFieldValue("pending_loss", e.target.value.replace(/[^0-9.,]/g, ""));
                                }}
                            />
                            {touched.pending_loss && errors.pending_loss && (
                                <div className={styles.error}>{errors.pending_loss}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "25%" }}>
                            <Select
                                placeholder="Değer Birimi"
                                options={[
                                    { label: "$ - Amerikan Doları", value: "dollar" },
                                    { label: "€ - Euro", value: "euro" },
                                    { label: "₺ - Türk Lirası", value: "lira" }
                                ]}
                                value={values.currency || undefined}
                                onChange={(value) => setFieldValue("currency", value)}
                            />
                            {touched.currency && errors.currency && (
                                <div className={styles.error}>{errors.currency}</div>
                            )}
                        </Flex>
                    </Flex>
                </Flex>
                <Flex vertical gap={12}>
                    <div>
                        <Typography className={styles.labelTitle}>Dosya Bilgileri</Typography>
                        <Typography className={styles.description}>* Bu alandaki bilgiler Siba Sigorta tarafından doldurulacaktır.</Typography>
                    </div>
                    <Flex gap="middle" justify="space-between">
                        <Flex vertical style={{ width: "30%" }}>
                            <Input
                                placeholder="Dosya No"
                                disabled={props.type === "CREATE"}
                                {...getFieldProps("policy_number")}
                            />
                            {touched.policy_number && errors.policy_number && (
                                <div className={styles.error}>{errors.policy_number}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "30%" }}>
                            <Input
                                placeholder="Eksper"
                                disabled={props.type === "CREATE"}
                                {...getFieldProps("expert")}
                            />
                            {touched.expert && errors.expert && (
                                <div className={styles.error}>{errors.expert}</div>
                            )}
                        </Flex>
                        <Flex vertical style={{ width: "30%" }}>
                            <Input
                                placeholder="Raportör"
                                disabled={props.type === "CREATE"}
                                {...getFieldProps("reporter")}
                            />
                            {touched.reporter && errors.reporter && (
                                <div className={styles.error}>{errors.reporter}</div>
                            )}
                        </Flex>
                    </Flex>
                    <Flex justify="space-between">
                        <Typography>{values.upload_files?.[0]?.name || "Ek Dosya"}</Typography>
                        <Flex vertical align="flex-end">
                            <input hidden ref={uploadInput} type="file" onChange={props.handleUploadFile} />
                            <Button className={styles.button} onClick={() => uploadInput.current?.click()}>
                                <PlusOutlined className={styles.buttonIcon} />
                                <div className={styles.buttonText}>Dosya Ekle</div>
                            </Button>
                        </Flex>
                    </Flex>
                    {props.type === "EDIT" &&
                        <Flex justify="space-between">
                            <Typography className={styles.labelTitle}>Durum Bilgisi</Typography>
                            <Select
                                id="recent_status"
                                value={props.formik.values.recent_status}
                                style={{ width: 175 }}
                                className={styles[`statusDropdown-${props.formik.values.recent_status}`]}
                                options={Object.keys(statuses).map(key => ({ label: statuses[key], value: key }))}
                                onChange={(value) => props.formik.setFieldValue("recent_status", value)}
                            />
                        </Flex>
                    }
                </Flex>
                <div>
                    <Typography className={styles.labelTitle}>Açıklama</Typography>
                    <Flex vertical>
                        <Input.TextArea
                            placeholder="Açıklama"
                            {...getFieldProps("description")}
                            rows={3}
                        />
                        {touched.description && errors.description && (
                            <div className={styles.error}>{errors.description}</div>
                        )}
                    </Flex>
                </div>
                <Typography className={styles.time}>{dayjs().format("YYYY-MM-DD - HH:mm")}</Typography>
                {props.type === "EDIT" &&
                    <Flex gap="small" align="flex-start">
                        <Checkbox
                            id="email_permission"
                            name="email_permission"
                            checked={props.formik.values.email_permission}
                            onChange={(e: any) => props.formik.setFieldValue("email_permission", e.target.checked)}
                        />
                        <div className={styles.mailCheckbox}>
                            Durum Bilgisi ‘Onaylandı’ Olarak Güncellendiğinde Hasar Bilgilerini Firmanın İlgili Modül Kullanıcılarına Mail Olarak İlet.
                        </div>
                    </Flex>
                }
            </Flex>
        </ModalDialog>
    );
};

export default DamageNotificationModal;
