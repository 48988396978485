import { IPage, ISearch, ISort } from "../../../store/reducers/tableReducer/type";
import { IDataSource, IInitialValues } from "./type";

export const prepareFetchParameters = (isExpired?: boolean, dates?: string[], search?: ISearch, sort?: ISort, page?: IPage): string => {
    const params = new URLSearchParams();

    page?.size && params.append("limit", page?.size.toString());
    typeof page?.number === "number" && params.append("offset", (page.size * page.number).toString());
    dates?.[0] && params.append('start_date', dates[0]);
    dates?.[1] && params.append('end_date', dates[1]);
    search?.key && search?.value && params.append(search.key, search.value);
    sort?.key && sort.order && params.append("ordering", sort.order === "ascend" ? sort.key : `-${sort.key}`)
    params.append("expired", isExpired ? "true" : "false");

    return params.size ? `?${params.toString()}` : '';
};

export const prepareFormData = (values: IInitialValues): FormData => {
    const formData = new FormData();
    values.uploadedFile.size > 0 && formData.append("upload_files", values.uploadedFile);
    formData.append("company", values.company);
    formData.append("policy_number", values.policyNumber);
    formData.append("start_date", values.startDate);
    formData.append("end_date", values.endDate);
    formData.append("policy_type", values.policyType);
    formData.append("email_permission", (!!values.email_permission).toString());

    return formData;
};

export const cleanupAfterSubmit = (
    setIsModalOpen: (open: boolean) => void,
    setSelectedPolicy: (policy?: IDataSource) => void,
    formikReset: () => void,
    handleFetchData: () => void
) => {
    setIsModalOpen(false);
    setSelectedPolicy(undefined);
    formikReset();
    handleFetchData();
};

export const setFormForSelectedPolicy = (selectedPolicy: IDataSource): IInitialValues => {
    return {
        company: selectedPolicy.company,
        policyNumber: selectedPolicy.policy_number,
        policyType: selectedPolicy.policy_type,
        uploadedFile: new File([""], selectedPolicy.additional_files[selectedPolicy.additional_files.length - 1]?.name || ""),
        startDate: selectedPolicy.start_date,
        endDate: selectedPolicy.end_date,
        email_permission: selectedPolicy.email_permission
    };
};
