import { FC, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { Button, Menu } from "antd";
import { CaretDownOutlined } from "@ant-design/icons";
import { MenuInfo } from "rc-menu/lib/interface";
import { items } from "./const";
import styles from "./index.module.scss";
import { logout } from "../../helpers/Auth";

const Sidemenu: FC = () => {
    const navigate = useNavigate();
    const location = useLocation();
    const [openKeys, setOpenKeys] = useState<string[]>([]);

    const handleMenuClick = ({ key }: MenuInfo) => {
        key && navigate(key);
    };

    const onOpenChange = (keys: string[]) => {
        const latestOpenKey = keys.find(key => !openKeys.includes(key));
        if (latestOpenKey) {
            setOpenKeys([latestOpenKey]);
        } else {
            setOpenKeys([]);
        }
    };

    const handleClickLogout = () => logout();

    return (
        <div className={styles.container}>
            <Menu
                defaultSelectedKeys={["/"]}
                mode="inline"
                theme="dark"
                selectedKeys={[location.pathname]}
                openKeys={openKeys}
                onOpenChange={onOpenChange}
                inlineCollapsed={false}
                expandIcon={<CaretDownOutlined />}
                items={items}
                className={styles.menu}
                onClick={handleMenuClick}
            />
            <div className={styles.logoutButtonContainer}>
                <Button onClick={handleClickLogout} className={styles.logoutButton}>
                    Çıkış Yap
                </Button>
            </div>
        </div>
    );
};

export default Sidemenu;
