import { Tag } from "antd";
import { IColumn } from "../../components/DataTable/type";
import { IDataSource } from "./type";
import styles from "./index.module.scss";
import { branches, cities, countries, damage_types } from "../../assets/conts";

export const getColumns = (): IColumn<IDataSource>[] => [
    {
      title: "Firma",
      dataIndex: "company",
      key: "company"
    },
    {
      title: "Dosya No",
      dataIndex: "policy_number",
      key: "policy_number"
    },
    {
      title: "Bağlı Dosya No",
      dataIndex: "parent",
      key: "parent"
    },
    {
      title: "Hasar Tipi",
      dataIndex: "damage_type",
      key: "damage_type",
      render: (_: any, record: IDataSource) =>
        <Tag className={styles[`${record.damage_type}Tag`]}>{damage_types[record.damage_type]}</Tag>
    },
    {
      title: "Branş",
      dataIndex: "branch",
      key: "branch",
      render: (_: any, record: IDataSource) => <div>{branches[record.branch]}</div>,
    },
    {
      title: "Hasar Yeri",
      dataIndex: "country",
      key: "country",
      render: (_, record: IDataSource) => <div>{countries.find(country => country.value === record.country?.toUpperCase())?.label} {cities.find(city => city.value === record.city) && `/ ${cities.find(city => city.value === record.city)?.label}`}</div>,
    }
  ];